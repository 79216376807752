/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import FavPagesService from '@/services/favPages.service';
import { FINISHED_LOADING, IS_LOADING } from '@/store/modules/layout/layoutActions.type';
import {
  ADMIN_CREATE_FAV_LINE,
  ADMIN_CREATE_FAV_LINE_CELL,
  ADMIN_DELETE_FAV_LINE,
  ADMIN_EDIT_FAV_LINE,
  ADMIN_EDIT_FAV_LINE_CELL,
  ADMIN_FETCH_FAV_PAGE,
  ADMIN_RESET_FAV_PAGE,
} from './favPageActions.type';
import {
  ADMIN_FETCH_FAV_PAGE_SUCCESS,
  ADMIN_RESET_FAV_PAGE_M,
} from './favPageMutations.type';

export default {
  state: {
    favPageData: {},
  },
  getters: {
    adminFavPageData(state) {
      return state.favPageData;
    },
  },
  mutations: {
    [ADMIN_FETCH_FAV_PAGE_SUCCESS](state, data) {
      Vue.set(state, 'favPageData', data.favPage);
    },
    [ADMIN_RESET_FAV_PAGE_M](state) {
      Vue.set(state, 'favPageData', {});
    },
  },
  actions: {
    [ADMIN_RESET_FAV_PAGE](context) {
      context.commit(ADMIN_RESET_FAV_PAGE_M);
    },
    [ADMIN_FETCH_FAV_PAGE](context, id) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavPagesService.get(`${id}?admin=true`)
            .then((response) => {
              context.commit(ADMIN_FETCH_FAV_PAGE_SUCCESS, response.data.data);
              context.dispatch(FINISHED_LOADING);
              resolve(response.data);
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING);
              reject(error);
            });
        });
      });
    },
    [ADMIN_CREATE_FAV_LINE](context, payload) {
      return new Promise((resolve, reject) => {
        FavPagesService.createAdminFavLine(payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ADMIN_EDIT_FAV_LINE](context, payload) {
      return new Promise((resolve, reject) => {
        FavPagesService.updateAdminFavLine(payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ADMIN_DELETE_FAV_LINE](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          FavPagesService.destroyFavLine(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [ADMIN_CREATE_FAV_LINE_CELL](context, payload) {
      return new Promise((resolve, reject) => {
        FavPagesService.createAdminFavLineCell(payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ADMIN_EDIT_FAV_LINE_CELL](context, payload) {
      return new Promise((resolve, reject) => {
        FavPagesService.updateAdminFavLineCell(payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
