<template>
    <div class="upgrade">
          <button v-if="this.limitType !== 'follow'"
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
        <span class="material-icons">close</span>
        </button>

        <md-content class="upgrade-body">
            <div class="main-section">
                <p class="premium-text">You discovered</p>
                <p class="premium-text">a <span class="premium-word">premium</span></p>
                <p class="premium-text">feature!</p>
            </div>

            <div class="main-section">
                <p class="limit-text">We're glad you're enjoying Wibki!</p>
                <p class="limit-text">However, you have reached the maximum number <br> of tabs or sites.</p>
                <p class="limit-text">To remove this limit and have an ad-free experience, <br> consider purchasing a premium subscription.</p>
            </div>

            <md-button class="md-raised md-primary site-save-button upgrade-btn" @click="changePlan()">
                {{ $t('userTopTabs.changePlan') }}
            </md-button>

        </md-content>
    </div>
</template>


<script>

export default {
  name: 'SubscriptionModal',
  props: {
    limitType: String,
  },
  methods: {
    changePlan() {
      this.$emit('close');
      this.$router.push({
        name: 'billing',
      });
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    isTabsLimit() {
      return this.limitType === 'tabs';
    },
  },
};
</script>

<style scoped>
.upgrade {
    height: 760px;
    background: linear-gradient(0deg, #C44B57 32.73%, #777777 73.92%);
    border: 1px solid #000000;
    padding: 25px;
}

.upgrade-body {
    height: 100%;
    padding: 15px;
    display: grid;
}

.main-section {
    text-align: center;
}

.premium-text {
    font-family: 'Mulish';
    font-weight: 800;
    font-size: 40px;
    line-height: 30%;
    letter-spacing: -0.3px;
    color: #635473;
}

.limit-text {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #635473;
}

.premium-word {
    color: #C44B57;
}

.upgrade-btn {
    margin-bottom: 25px;
    width: 200px;
    border-radius: 8px;
}

.btn-close {
    color: white;
    padding: 5px;
}
</style>
