<template>
  <div class="page-container">
    <nprogress-container></nprogress-container>
    <md-app id="app" md-mode="fixed">
      <md-app-toolbar :currentRouteName="currentRouteName" id="rec-header" md-elevation="1"
        v-if="currentRouteName !== 'bookmarklet'">
        <rec-header></rec-header>
      </md-app-toolbar>
      <md-app-drawer :md-active.sync="isSidebarVisible" :class="[isMobile ? 'md-left mobile-siderbar' : 'md-right']"
        v-if="currentRouteName !== 'userLogin' && currentRouteName !== 'bookmarklet'">
        <rec-sidebar v-if="!isMobile"></rec-sidebar>
        <rec-sidebar-mobile v-else></rec-sidebar-mobile>
      </md-app-drawer>

      <md-app-content id="content-app">
        <LandingPage v-if="isLandingCase"></LandingPage>
        <div v-else class="content-app-container">
          <router-view></router-view>
          <rec-ads :HasNotAdsSection="HasNotAdsSection" :hasSubscription="hasSubscription" adsType="bottom"
            class="more-lower-space">
          </rec-ads>
          <CellModal :isCellModalVisible="selectedTabCellIdToEdit" @close="closeModal" />
          <ShareTabModal :isShareTabModalVisible="isShareTabModalVisible" :sharedTabObject="sharedTabObject"
            v-show="isShareTabModalVisible" @close="closeSharedModal">
          </ShareTabModal>
          <v-tour name="myTour" :steps="steps" id="tour"></v-tour>
        </div>
        <rec-footer :HasNotAdsSection="HasNotAdsSection" :hasSubscription="hasSubscription"
          v-if="currentRouteName !== 'userLogin' && currentRouteName !== 'userRegister' && currentRouteName !== 'bookmarklet' &&
          currentRouteName !== 'step1Login' && !isLandingCase">
        </rec-footer>

      </md-app-content>
    </md-app>

    <rec-cookie v-if="isCookieVisible" />
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import loaderMixin from '@/mixins/loaderMixin';
import RecAds from '@/components/layout/RecAds.vue';
import RecHeader from '@/components/layout/RecHeader.vue';
import RecSidebar from '@/components/layout/RecSidebar.vue';
import RecSidebarMobile from '@/components/layout/RecSidebarMobile.vue';
import RecFooter from '@/components/layout/RecFooter.vue';
import RecCookie from '@/components/layout/RecCookie.vue';
import LandingPage from '@/components/common/LandingPage.vue';
import CellModal from '@/components/modals/CellModal.vue';
import ShareTabModal from '@/components/modals/ShareTabModal.vue';
import { RESET_TAB_CELL_EDITABLE_ID_ADMIN } from '@/store/actions.type';
import sidebarMixin from '@/mixins/sidebarMixin';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import {
  RESET_TAB_CELL_EDITABLE_ID, GET_AUTH_USER_TABS, SET_SHARE_TAB_MODAL_VALUE, SET_TAB_MODAL_VALUE, SET_EDITABLE_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import { SET_TOUR } from '@/store/modules/layout/layoutActions.type';
import landingMixin from '@/mixins/landingMixin';
import { UPDATE_UI_CHANNEL } from './updateUiChannel';
import { USER_SPRITE_UPDATED_EVENT } from './pusher';

export default {
  name: 'RECApp',
  components: {
    RecAds,
    RecHeader,
    RecSidebar,
    RecFooter,
    NprogressContainer,
    CellModal,
    RecSidebarMobile,
    RecCookie,
    ShareTabModal,
    LandingPage,
  },
  mixins: [
    sidebarMixin,
    smallDevicesCheckMixin,
    loaderMixin,
    landingMixin,
  ],
  data: () => ({
    windowWidth: null,
    centerVideoAd: null,
    leftVideoAd: null,
    isNotOverlapped: false,
    isCellModalVisible: false,
    steps: [
      {
        target: '[data-v-step="0"]',
        content: '<h2 style="line-height: initial; font-size: 17px;"> Wibki serves as your starting point for the Web. </h2>'
          + '<p style="font-size: 15px;"> An easy way to access all your Web favorites.</p>',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="1"]',
        content: 'This is the user menu.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="2"]',
        content: 'Your tabs where you can save sites.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="3"]',
        content: 'Click the plus icon to create a new tab.',
        params: {
          placement: 'bottom',
        },
      },
      // {
      //   target: '[data-v-step="4"]',
      //   content: 'Click on more button to expand the tab list',
      //   params: {
      //     placement: 'bottom',
      //   },
      // },
      {
        target: '[data-v-step="5"]',
        content: 'Click on the pencil icon to edit each tab.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="6"]',
        content: 'There are your saved sites. <br>  Click on it to access the saved site.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="7"]',
        content: 'Click on pencil icon to edit the cell sites.',
        params: {
          placement: 'bottom',
        },
      },
      {
        target: '[data-v-step="8"]',
        content: 'Click on plus icon to add a new site.',
        params: {
          placement: 'bottom',
        },
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isPaidUser() {
      return this.user !== null && this.user.plan_id != null;
    },
    isPremiumUser() {
      return this.user !== null && this.user.premium != null;
    },
    isAdminUser() {
      return this.user !== null && this.user.role === 'admin';
    },
    hasSubscription() {
      return this.isPaidUser || this.isPremiumUser || this.isAdminUser;
    },
    HasNotAdsSection() {
      return (this.$route.name) ? (this.$route.name.includes('user')
        || this.$route.name.includes('admin')
        || this.$route.name.includes('aboutUs')
        || this.$route.path.includes('user-settings')
        || this.$route.name.includes('privacy')
        || this.$route.name.includes('step1Login')
        || this.$route.name.includes('step2Login')
        || this.$route.name.includes('mostPopularSites')
        || this.$route.name.includes('termsConditions'))
        || this.isLandingCase : true;
    },
    selectedTabCellIdToEdit: {
      get() {
        if (this.$store.getters.selectedTabCellIdToEdit === undefined) {
          return true;
        }
        if (this.$store.getters.selectedTabCellIdToEdit !== null && this.$store.getters.selectedTabCellIdToEdit !== -1) {
          return true;
        }
        if (this.$store.getters.selectedTabCellIdToEditAdmin !== null) {
          return true;
        }
        return false;
      },
    },
    tour() {
      return this.$store.getters.tour;
    },
    isSidebarVisible() {
      return this.$store.getters.isSidebarVisible;
    },
    isCookieVisible() {
      return !this.$store.getters.acceptCookies;
    },
    isShareTabModalVisible() {
      return this.$store.getters.isShareTabModalVisible;
    },
    sharedTabObject() {
      return this.$store.getters.selectedTab;
    },
  },
  updated() {
    this.$nextTick(function vv() {
      if (this.tour) {
        this.$tours.myTour.start();
        this.$store.dispatch(SET_TOUR, false);
      }
    });
    this.handleConnatix();
  },
  mounted() {
    if (this.user?.id) {
      this.$store.dispatch(GET_AUTH_USER_TABS).then().catch();
    }
    this.checkDevice();
    this.smallDeviceCheck();
    this.loadPusher();
    setInterval(() => {
      this.handleGoogleAds();
    }, (1000));
    setTimeout(() => {
      this.handleConnatix();
    }, 1000);
  },
  methods: {
    handleConnatix() {
      const connatixDiv = document.getElementsByClassName('cnx-center')[0];
      if (connatixDiv !== undefined) {
        if (this.HasNotAdsSection || this.hasSubscription) {
          connatixDiv.style.display = 'none';
        } else {
          connatixDiv.style.display = '';
        }
        connatixDiv.style.width = '100%';
        connatixDiv.style.maxHeight = '350px';
      }
      document.getElementsByTagName('main')[0].style.transform = 'none';
    },
    handleGoogleAds() {
      if (this.HasNotAdsSection || this.hasSubscription) {
        const scripts = document.head.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i += 1) {
          const src = scripts[i].getAttribute('src');
          if (src && src.includes('pagead2.googlesyndication')) {
            document.head.removeChild(scripts[i]);
          }
        }
        const topAd = document.getElementsByTagName('ins')[1];
        if (topAd !== undefined) {
          topAd.style.display = 'none';
          document.body.style.padding = 0;
        }
      }
    },
    loadPusher() {
      UPDATE_UI_CHANNEL.bind(USER_SPRITE_UPDATED_EVENT, (data) => {
        if (Number(data?.user_id) === this.user?.id) {
          this.$store.dispatch(GET_AUTH_USER_TABS).then();
        }
      });
    },
    closeModal() {
      this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID);
      this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID_ADMIN);
      // cuz state isnot reset to false if you close it by clicking outside
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
    },
    closeSharedModal() {
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom,
        top: rect.top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: calc(100vh - 2px);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#content-app {
  background-color: #F0F0ED;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}

#content-app-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#left-video-ad.hide {
  opacity: 0;
}

.md-app {
  min-height: 100%;

  main {
    transform: none !important;
  }

  .md-drawer+main {
    display: none;
  }

  .md-content {
    padding: 0;
    border: none;
  }
}

.md-drawer {
  width: 200px;
  max-width: calc(100vw - 125px);
}

.container-search {
  width: 70%;
  margin: 50px auto;
}

.mobile-siderbar {
  width: 281px;
}

.more-lower-space {
  height: 150px;
  padding-bottom: 2rem;
  padding-top: 1.3rem;
}

::v-deep #tour .v-step {
  max-width: 475px;
}

::v-deep #tour .v-step .v-step__content :first-child {
  font-size: 17px;
}

::v-deep #tour .v-step .v-step__button {
  padding-left: 15px;
  padding-right: 15px;
  height: 45px;
  width: 130px;
  font-weight: 600;
}
</style>
