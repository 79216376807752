import {
  SET_SELECTED_TABA, SET_SHARE_TAB_MODAL_VALUE, SET_EDITABLE_TAB_MODAL_VALUE, SET_TAB_MODAL_VALUE,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  data: () => ({
  }),
  computed: {
    userId() {
      return this.$store.getters.user?.id;
    },
  },
  methods: {
    showTabModalForEditTab() {
      this.close();
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, true);
    },
    showTabModalForAddNewTab() {
      this.close();
      this.$store.dispatch(SET_TAB_MODAL_VALUE, true);
    },
    showShareModal() {
      this.close();
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, true);
    },
    close() {
      this.isModalVisibleCell = false;
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
      this.$emit('close');
    },
    setCurrentEditTabObj(tab) {
      if (tab) {
        this.tabId = tab.id; // Get Id for Add Modal
        // Used only on Edit. The current tab obj is converted into an array because of the "deep" watch flag into tabmodal child component
        const tabArr = {
          position: tab.position,
          tabId: tab.id,
          name: tab.name,
          description: tab.description,
          privatetab: tab.privatetab,
          id: this.$store.getters.userData.id,
          tabCells: tab.tabCells,
        };
        const objectArray = Object.entries(tabArr);
        this.currentEditTabObj = tab ? objectArray : null;
        this.selectedTabObj = tab;
        this.$store.dispatch(SET_SELECTED_TABA, tab);

        this.setSelectedTabDescription(tab.description);
      }
    },
    setUserHasTabs(value) {
      this.$emit('setUserHasTabs', value);
    },
    setSelectedTabDescription(value) {
      this.selectedTabDescription = value;
    },
    login() {
      this.$router.push({
        name: 'userLogin',
      });
    },
  },
};
