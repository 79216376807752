<template>
  <div id="footer" class="md-layout md-alignment-center-center">
    <div class="md-layout-item md-size-100 md-xsmall-size-100">
      <div id="rec-footer-top" class="md-layout md-alignment-center-top">
        <div class="md-layout-item md-size-40 md-small-size-100">
          <div class="footer-logo">
            <img id="logo" class="wibki-footer-logo" :src="`${publicPath}wibki.png`" alt="wibki header logo" @click="home" data-v-step="0">
            <div class="footer-link get-started-sentence">
              <router-link class="get-started" tag="a" :to="{ name: 'userLogin' }">
                Your key to the Web
              </router-link>
            </div>
          </div>
        </div>
        <div class="footer-links-container md-layout-item md-size-60 md-small-size-100">
          <div class="footer-menu">
            <router-link tag="a" :to="{ name: 'billing' }" v-if="!isNotLogged" class="footer-menu-item">
              Upgrade your plan
            </router-link>
            <div class="footer-menu-item">
                <a href="https://blog.wibki.com" target="_blank">
                  Blog
                </a>
            </div>
            <router-link tag="a" :to="{ name: 'aboutUs' }" class="footer-menu-item">
              {{ $t('footer.aboutUs') }}
            </router-link>
            <router-link tag="a" :to="{ name: 'contact' }" class="footer-menu-item">
              {{ $t('footer.contactUs') }}
            </router-link>
            <router-link tag="a" :to="{ name: 'faq' }" class="footer-menu-item">
              {{ $t('footer.faq') }}
            </router-link>
            <div id="social-menu" class="footer-menu-item">
              <a href="https://twitter.com/getwibki" class="md-flat md-icon-button md-button" style="padding-top:7px;"
                target="_blank">
                <md-icon>
                  <i class="fab fa-twitter fab-2xl"></i>
                </md-icon>
              </a>
              <a href="https://www.facebook.com/getwibki/" class="md-flat md-icon-button md-button"
                style="padding-top:7px;" target="_blank">
                <md-icon>
                  <i class="fab fa-facebook-square"></i>
                </md-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="rec-footer-bottom" class="md-layout md-alignment-center-center">
        <div class="md-layout-item md-alignment-center-center">
          <div id="privacy-menu">
            <router-link tag="a" :to="{ name: 'privacy' }">
              {{ $t('footer.privacy') }}
            </router-link>
            <span class="separator">|</span>
            <router-link tag="a" :to="{ name: 'termsConditions' }">
              {{ $t('footer.terms') }}
            </router-link>
          </div>
          <div class="copyright-container">
            &copy;
            <router-link tag="a" :to="{ name: 'home' }">
              Wibki
            </router-link>
            . {{ $t('footer.rights') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';

export default {
  name: 'RecFooter',
  mixins: [
    smallDevicesCheckMixin,
    sidebarMixin,
    loggedInUserMixin,
  ],
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isNotLogged() {
      return this.$store.getters.user === null;
    },
  },
  methods: {
    home() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    windowWidth: null,
    isMobile: false,
  }),
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/rec-footer.scss';
</style>
