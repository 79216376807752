/* eslint-disable import/no-cycle,no-shadow */
import LocalStorageService from '@/services/localStorage.service';
import { FETCH_NAVIGATION_STRUCTURE } from '@/store/modules/navigationStructure/navigationStructureActions.type';
import NavigationStructureService from '@/services/navigationStructure.service';
import { FETCH_NAVIGATION_STRUCTURE_SUCCESS } from '@/store/modules/navigationStructure/navigationStructureMutations.type';

const state = {
  navigationStructure: LocalStorageService.getNavigationStructure(),
};

const getters = {
  navigationStructure(state) {
    return state.navigationStructure;
  },
};
const actions = {
  [FETCH_NAVIGATION_STRUCTURE](context) {
    return new Promise((resolve, reject) => {
      NavigationStructureService.get()
        .then((response) => {
          context.commit(FETCH_NAVIGATION_STRUCTURE_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  [FETCH_NAVIGATION_STRUCTURE_SUCCESS](state, data) {
    state.navigationStructure = data;
    LocalStorageService.setNavigationStructure(data);
  },
};


export default {
  state,
  actions,
  mutations,
  getters,
};
