import loggedInUserMixin from '@/mixins/loggedInUserMixin';

export default {
  data: () => ({
  }),
  mixins: [
    loggedInUserMixin,
  ],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isLandingCase() {
      return !this.isAuthenticated && (this.currentRouteName === 'home' || this.currentRouteName === null);
    },
  },
};
