<template>
  <div class="header-container">
    <div id="logo-header-container" class="md-layout-item md-size-20 element-center">
      <img id="logo" :src="`${publicPath}wibki.png`" alt="wibki header logo" data-v-step="0">
    </div>
    <div class="md-layout-item md-size-20 element-center login-btn-container">
      <md-button md-menu-trigger class="login-btn" :to="{ name: 'userLogin' }">
        Login
      </md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingHeader',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>

<style lang="scss">
.header-container {
  display: flex;
  width: 100%;
  height: 73px;
  align-items: center;
  justify-content: space-between;
}

#logo {
  max-width: 10rem;
  width: 10rem;
  cursor: pointer;
}

.login-btn {
  font-size: 18px;
  text-transform: capitalize;
  border-radius: 8px;
  color: #C44B57 !important;
}
</style>
