/* eslint-disable arrow-body-style */
import Vue from 'vue';

export default {
  getUserTabs() {
    return Vue.axios.get('/auth/user?source=wibki').catch((error) => {
      throw new Error(`${error}`);
    });
  },
  createUserTab(payload) {
    return Vue.axios.post('/user-tabs', payload).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  updateUserTab(payload) {
    return Vue.axios.put(`/user-tabs/${payload.tabId}`, payload).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  deleteUserTab(payload) {
    return Vue.axios.delete(`/user-tabs/${payload}`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
