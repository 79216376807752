<template>
  <div id="rec-header-content" class="md-toolbar-row rec-header-content-mobile">
    <div class="md-toolbar-section-start" v-if="!isMobile">
      <template v-if="!isLandingCase">
        <div id="logo-header-container" class="md-layout-item md-size-25 element-center">
          <img id="logo" :src="`${publicPath}wibki.png`" alt="wibki header logo" @click="home" data-v-step="0">
        </div>
        <search-bar class="md-layout-item md-size-55" :currentRouteName="currentRouteName"></search-bar>
        <div class="md-toolbar-section-end md-layout-item element-center" v-if="!isMobile">
          <md-button md-menu-trigger class="md-icon-button" v-if="!isAuthenticated" :to="{ name: 'userLogin' }">
            <md-avatar>
              <img :src="`${publicPath}profile-image.png`">
            </md-avatar>
          </md-button>
          <md-menu md-size="big" md-align-trigger v-if="isAuthenticated" :md-offset-y="y" :md-offset-x="x">
            <div class="user-acc">
              <p class="user-username">{{ user.username }}</p>
              <md-button md-menu-trigger class="md-flat md-icon-button avatar-button">
                <md-avatar data-v-step="1">
                  <img :src="user.userProfileImagePath" alt="Avatar" v-if="user.userProfileImagePath">
                  <img :src="`${publicPath}profile-image.png`" v-else>
                </md-avatar>
              </md-button>
            </div>
            <md-menu-content class="profile-menu">
              <md-menu-item class="nav-menu-button-sidebar" @click="myFaves"> {{
                $t('navbar.myFaves')
              }}
              </md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'faveItButton' }"> {{
                $t('navbar.faveButton')
              }}
              </md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" href="https://blog.wibki.com" target="_blank">
                {{ $t('navbar.blog') }}
              </md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'faq' }"> {{ $t('navbar.faq') }}</md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'account' }"> {{
                $t('navbar.settings')
              }}
              </md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" :to="{ name: 'adminDashboard' }" v-if="user.role == 'admin'">
                Admin Dashboard
              </md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar help-btn" @click="enableTour"> Help</md-menu-item>
              <md-menu-item class="nav-menu-button-sidebar" v-if="isAuthenticated" @click="logout"> {{
                $t('logout')
              }}
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </template>
      <template v-else>
        <LandingHeader></LandingHeader>
      </template>
    </div>

    <div class="md-layout md-layout-mobile" v-if="isMobile">
      <div class="md-layout-item nav-item-mobile">
        <md-button id="mobile-sidebar" class="md-icon-button" @click="toggleSidebar" data-v-step="1">
          <i class="fas fa-bars fa-2x"></i>
        </md-button>
        <div id="logo-header-container-mobile">
          <img id="logo-mobile" :src="`${publicPath}wibki.png`" alt="wibki header logo" @click="home" data-v-step="0">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';
import landingMixin from '@/mixins/landingMixin';
import SearchBar from '@/components/common/SearchBar.vue';
import LandingHeader from '@/components/layout/LandingHeader.vue';
import {
  GET_AUTH_USER_TABS,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'RecHeader',
  components: {
    SearchBar,
    LandingHeader,
  },
  mixins: [
    smallDevicesCheckMixin,
    sidebarMixin,
    loggedInUserMixin,
    landingMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
    windowWidth: null,
    x: -90,
    y: 10,
    mdOffsetX: -30,
  }),
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  methods: {
    enableTour() {
      this.$tours.myTour.start();
    },
    myFaves() {
      if (this.currentRouteName === 'home') {
        this.$router.push({ name: 'myFaves', params: { username: this.user.username } });
      } else {
        this.$router.push({ name: 'myFaves', params: { username: this.user.username } });
        this.$router.go(this.$router.currentRoute);
      }
    },
    home() {
      if (this.$route.path !== '/') {
        this.$store.dispatch(GET_AUTH_USER_TABS).then().catch();
        this.$router.push('/');
      }
    },
  },
  computed: {
  },
};
</script>

<style lang="scss">
.profile-menu {
  background: #FFFFFF !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22) !important;
  border-radius: 12px !important;
  max-height: unset !important;
  top: 10px;
  z-index: 999999999999;

  .md-menu-content-container {
    border-radius: 12px !important;
  }

  .help-btn {
    .md-list-item-button {
      color: #1020A9 !important;
    }
  }

  .md-list-item-button {
    &:hover {
      border-radius: 6px;
      color: #c44b57 !important;
    }

    color: #D00A0A !important;
  }
}

.multiselect__element:hover {
  background-color: #c44b57;
  border-radius: 6px;
}

.user-acc {
  display: flex;
  justify-content: center;
  align-items: center;

}

.user-username {
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-all;
  max-height: 115px;
  overflow-y: auto
}

@import '@/assets/scss/layout/rec-header.scss';
</style>
