export const GET_AUTH_USER_TABS = 'getAuthUserTabs';
export const CREATE_USER_TAB = 'createUserTab';
export const UPDATE_USER_TAB = 'updateUserTab';
export const DELETE_USER_TAB = 'deleteUserTab';

export const CREATE_TAB_CELL = 'createTabCell';
export const UPDATE_TAB_CELL = 'updateTabCell';
export const DELETE_TAB_CELL = 'deleteTabCell';

export const UPLOAD_IMAGE_TAB_CELL = 'uploadImageTabCell';

export const SET_SELECTED_TAB_INDEX = 'setSelectedTabIndex';
export const SET_SELECTED_TAB_NAME_BY_SEARCH = 'setSelectedTabNameBySearch';
export const SET_SELECTED_TABA = 'setSelectedTab';
export const RESET_SELECTED_TAB_INDEX = 'resetSelectedTabIndex';
export const SET_HIDE_LINES = 'setHideLines';

export const SET_TAB_CELL_EDITABLE_ID = 'setTabCellEditableID';
export const RESET_TAB_CELL_EDITABLE_ID = 'resetTabCellEditableID';
export const ADMIN_MODE = 'adminMode';

export const UPDATE_TAB_CELL_POSITION = 'updateTabCellPosition';

export const SET_TAB_MODAL_VALUE = 'setTabModalValue';
export const SET_SHARE_TAB_MODAL_VALUE = 'setShareTabModalValue';
export const SET_EDITABLE_TAB_MODAL_VALUE = 'setEditableTabModalValue';
