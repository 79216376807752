<template>
  <div class="md-layout md-alignment-center-space-around landing-page-container">
    <div class="md-layout-item landing-page-wrapper">
      <div class="landing-text-wrapper">
        <h2>Your Web, <br>One Touch Away</h2>
        <p>Wibki is a fresh way to explore the web with style and speed. Our visual tool will revive your browsing
          experience.</p>
        <md-button md-menu-trigger class="login-btn" :to="{ name: 'userLogin' }">
          Get Started
        </md-button>
      </div>
      <div class="landing-image-wrapper">
        <img id="landing-image" :src="`${publicPath}landing-page.svg`" alt="wibki landing-page" data-v-step="0">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  methods: {
    login() {
      this.$router.push({
        name: 'userLogin',
      });
    },
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>

<style lang="scss">
.landing-page-container {
  height: calc(100vh - 108px);
  background: linear-gradient(90deg, rgba(196, 75, 87, 1) 0%, rgba(200, 94, 105, 1) 31%, rgba(206, 119, 128, 1) 58%, rgba(231, 231, 231, 1) 100%);
}

.landing-page-wrapper {
  display: flex;
  height: 100%;
  padding: 70px 10px 70px 70px;
}

.landing-text-wrapper {
  color: #fff;
  width: 40%;
  display: flex;
  flex-flow: column;
  padding: 20px 0 0 60px;

  h2 {
    font-size: 65px;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  p {
    font-size: 22px;
    line-height: 1.5;
    width: 80%;
    margin-bottom: 40px;
  }

  .login-btn {
    height: 65px;
    width: 200px;
    font-size: 20px;
    border: none;
    border-radius: 8px;
    color: #C44B57;
    background-color: #fff;
    font-weight: 600;
    cursor: pointer;
    margin: 0;
  }
}

.landing-image-wrapper {
  width: 60%;
  display: flex;

  img {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 1024px) {
  .landing-page-container {
    height: 100%;
  }

  .landing-page-wrapper {
    flex-flow: column-reverse;
    justify-content: flex-end;
    padding: 50px;

    .login-btn {
      height: 55px;
      width: 160px;
      font-size: 18px;
    }
  }

  .landing-text-wrapper,
  .landing-image-wrapper {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 480px) {
  .landing-text-wrapper {
    padding: 0;

    h2 {
      font-size: 35px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

@media (min-height: 0px) and (max-height: 900px) and  (min-width: 1025px) {
  .landing-text-wrapper {
    h2 {
      font-size: 50px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
}
</style>
