/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UsersService from '@/services/users.service';

import {
  UPDATE_ACTIVE_SORT_FIELD_USERS,
  UPDATE_SEARCH_USERS,
  CLEAR_SEARCH_USERS,
  CREATE_USER, FETCH_ADMIN_USERS,
} from './usersActions.type';
import {
  FETCH_USERS_SUCCESS,
  UPDATE_ACTIVE_SORT_FIELD_USERS_M,
  UPDATE_SEARCH_USERS_M,
  CLEAR_SEARCH_USERS_M,
} from './usersMutations.type';
import { FINISHED_LOADING, IS_LOADING } from '../../../layout/layoutActions.type';

export default {
  state: {
    data: [],
    perPage: 25,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    total: 10,
    sort: [
      {
        name: 'ID ASC',
        key: 'id',
        dir: 'ASC',
        code: 'id_ASC',
        active: true,
      },
      {
        name: 'ID DESC',
        key: 'id',
        dir: 'DESC',
        code: 'id_DESC',
        active: false,
      },
      {
        name: 'Newest',
        key: 'created_at',
        dir: 'DESC',
        code: 'created_at_DESC',
        active: false,
      },
      {
        name: 'Oldest',
        key: 'created_at',
        dir: 'ASC',
        code: 'created_at_ASC',
        active: false,
      },
      {
        name: 'A-Z by username',
        key: 'username',
        dir: 'ASC',
        code: 'username_ASC',
        active: false,
      },
      {
        name: 'Z-A by username',
        key: 'username',
        dir: 'DESC',
        code: 'username_DESC',
        active: false,
      },
      {
        name: 'A-Z by email',
        key: 'email',
        dir: 'ASC',
        code: 'email_ASC',
        active: false,
      },
      {
        name: 'Z-A by email',
        key: 'email',
        dir: 'DESC',
        code: 'email_DESC',
        active: false,
      },
      {
        name: 'Deleted First',
        key: 'deleted_at',
        dir: 'DESC',
        code: 'deleted_desc',
        active: false,
      },
      {
        name: 'User Tabs Increasing',
        key: 'user_tabs_count',
        dir: 'ASC',
        code: 'user_tabs_count_ASC',
        active: false,
      },
      {
        name: 'User Tabs Decreasing',
        key: 'user_tabs_count',
        dir: 'DESC',
        code: 'user_tabs_count_DESC',
        active: false,
      },
      {
        name: 'Files Count Increasing',
        key: 'files_count',
        dir: 'ASC',
        code: 'files_count_ASC',
        active: false,
      },
      {
        name: 'Files Count Decreasing',
        key: 'files_count',
        dir: 'DESC',
        code: 'files_count_DESC',
        active: false,
      },
      {
        name: 'Plan',
        key: 'plan_id',
        dir: 'DESC',
        code: 'plan_desc',
        active: false,
      },
      {
        name: 'Plan Expired Asc',
        key: 'next_payment_date',
        dir: 'ASC',
        code: 'next_payment_date_ASC',
        active: false,
      },
      {
        name: 'Plan Expired Desc',
        key: 'next_payment_date',
        dir: 'DESC',
        code: 'next_payment_date_DESC',
        active: false,
      },
      {
        name: 'Premium First',
        key: 'premium',
        dir: 'DESC',
        code: 'premium_desc',
        active: false,
      },
      {
        name: 'Last Login',
        key: 'last_login',
        dir: 'DESC',
        code: 'alst_login_desc',
        active: false,
      },
      {
        name: 'Ip',
        key: 'ip',
        dir: 'DESC',
        code: 'ip_desc',
        active: false,
      },
    ],
    search: '',
  },
  getters: {
    usersItems(state) {
      return state.data;
    },
    usersPerPage(state) {
      return state.perPage;
    },
    usersCurrentPage(state) {
      return state.currentPage;
    },
    usersLastPage(state) {
      return state.lastPage;
    },
    usersFrom(state) {
      return state.from;
    },
    usersTo(state) {
      return state.to;
    },
    usersTotal(state) {
      return state.total;
    },
    usersSortFields(state) {
      return state.sort;
    },
    usersActiveSort(state) {
      const activeSorts = state.sort.filter(sort => sort.active === true);
      return activeSorts[0];
    },
    usersSearch(state) {
      return state.search;
    },
  },
  mutations: {
    [FETCH_USERS_SUCCESS](state, data) {
      state.data = data.data;
      state.perPage = parseInt(data.perPage, 10);
      state.currentPage = data.currentPage;
      state.lastPage = data.lastPage;
      state.from = data.from;
      state.to = data.to;
      state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_USERS_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_SEARCH_USERS_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_USERS_M](state) {
      Vue.set(state, 'search', '');
    },
  },
  actions: {
    [FETCH_ADMIN_USERS](context, params) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          // check search
          if (context.state.search && context.state.search !== '') {
            params.search = context.state.search;
          }
          // check active sort
          const activeSortArr = context.state.sort.filter(sort => sort.active === true);
          if (activeSortArr.length > 0) {
            params.sortBy = activeSortArr[0].key;
            params.sortDir = activeSortArr[0].dir;
          }

          UsersService.queryAdmin(params)
            .then((response) => {
              context.commit(FETCH_USERS_SUCCESS, response.data.data);
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [CREATE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        context.dispatch(IS_LOADING).then(() => {
          UsersService.create(payload)
            .then((response) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            })
            .catch((error) => {
              context.dispatch(FINISHED_LOADING).then(() => {
                reject(error);
              });
            });
        });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_USERS](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_USERS_M, params);
    },
    [UPDATE_SEARCH_USERS](context, params) {
      context.commit(UPDATE_SEARCH_USERS_M, params);
    },
    [CLEAR_SEARCH_USERS](context) {
      context.commit(CLEAR_SEARCH_USERS_M);
    },
  },
};
