export default {
  data: () => ({
    userTabsAttr: [],
    originalTabs: [],
  }),
  methods: {
    setUserTabs() {
      // Get the user tabs from the store
      const { userTabs } = this.$store.getters.userData;
      const currentUser = this.$store.getters.user;

      // Check the different scenarios and assign the userTabsAttr accordingly
      if (this.adminMode) {
        // Admin mode: show all tabs
        this.userTabsAttr = userTabs;
      } else if (this.publicUsername) {
        // Myfaves page: show only public tabs for other users, or all tabs for current user
        const isCurrentUser = currentUser?.id === this.publicUsername.id;
        this.userTabsAttr = isCurrentUser ? this.$store.getters.userTabs : userTabs.filter(tab => !tab.privatetab);
      } else {
        // Default case: show the user tabs from the store
        this.userTabsAttr = this.$store.getters.userTabs;
      }
      // Save the original tabs for later use
      this.originalTabs = this.userTabsAttr;
      this.setUserHasTabs(this.userTabsAttr?.length > 0 ?? false);
    },
  },
};
