<template>
  <div v-if="!HasNotAdsSection && !hasSubscription" class="md-layout md-alignment-center-center ads-container container">
    <div class="ads-div md-alignment-center-center container-fluid">
      <Adsense
        style="width: 100%; height: 100%"
        ins-style="display:inline-block;width: 100%; height: 100%"
        :data-ad-client="this.adClient"
        :data-ad-slot="this.adSlot">
      </Adsense>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecAds',
  props: {
    adsType: {
      String,
    },
    HasNotAdsSection: Boolean,
    hasSubscription: Boolean,
  },
  data() {
    return {
      adSlot: null,
      adClient: null,
    };
  },
  mounted() {
    this.adClient = 'ca-pub-1569923671775194';
    switch (this.adsType) {
      case 'top':
        this.adSlot = '4647408869';
        break;
      case 'bottom':
        this.adSlot = '1215747542';
        break;
      default:
        this.adSlot = null;
    }
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/layout/rec-ads.scss';

  #aswift_0_host {
    width: 100% !important;
  }

  #aswift_1_host {
    width: 100% !important;
  }
</style>
