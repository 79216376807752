<template>
  <div id="sidebar-mobile" class="page-container">
    <md-toolbar class="md-transparent md-sidebar-mobile-header md-alignment-center-center" md-elevation="0">
      <img id="logo-mobile" :src="`${publicPath}wibki.png`" alt="wibki header logo" @click="home">
      <md-button class="md-icon-button md-right" @click="toggleSidebar"  >
        <i class="fas fa-times fa-2x"></i>
      </md-button>
    </md-toolbar>

    <md-list :md-expand-single="expandSingle" class="md-dense sidebar-mobile-list">

      <router-link v-if="isAuthenticated" :to="{name: 'myFaves', params: {username: this.user.username }}" tag="md-list-item" :class="'menu-item'">
            <span class="md-list-item-text" @click="toggleSidebar"> {{ $t('navbar.myFaves') }}</span>
      </router-link>

      <router-link tag="md-list-item" :to="{ name: 'account'}" :class="'menu-item'" exact>
        <span class="md-list-item-text"  @click="toggleSidebar"> {{ $t('navbar.settings') }}</span>
      </router-link>

      <md-list-item  @click="enableTour" :class="'menu-item help-item'">
        <span class="md-list-item-text" > Help </span>
      </md-list-item>

      <md-list-item v-if="isAuthenticated" @click="logout" :class="'menu-item'">
        <span class="md-list-item-text"  @click="toggleSidebar"> {{ $t('logout') }}</span>
      </md-list-item>

      <md-list-item v-if="!isAuthenticated" :to="{name: 'userLogin'}" :class="'menu-item'">
        <span class="md-list-item-text"  @click="toggleSidebar"> {{ $t('loginBtn') }}</span>
      </md-list-item>
      <md-list-item v-if="isAuthenticated" class="md-alignment-center-center avatar-image">
        <md-avatar id="user-profile-pic">
          <img :src="user.userProfileImagePath" alt="Avatar" v-if="user.userProfileImagePath">
          <img :src="`${publicPath}profile-image.png`" v-else>
        </md-avatar>
        <span class="user-avatar-text" v-if="user">{{ user.username }}</span>
      </md-list-item>
      <!--      ====================== Admin =====================                   -->
      <md-divider v-if="isAdmin"></md-divider>
      <md-subheader v-if="isAdmin">Admin menu</md-subheader>

      <router-link v-if="isAdmin" tag="md-list-item" :to="{ name: 'adminDashboard'}" :class="'menu-item'">
        <span class="md-list-item-text"  @click="toggleSidebar">Dashboard</span>
      </router-link>
      <router-link v-if="isAdmin" tag="md-list-item" to="/admin/users">
        <span class="md-list-item-text"  @click="toggleSidebar">Users</span>
      </router-link>
      <router-link v-if="isAdmin" tag="md-list-item" to="/admin/sites">
        <span class="md-list-item-text"  @click="toggleSidebar">Sites</span>
      </router-link>
      <router-link v-if="isAdmin" tag="md-list-item" to="/admin/unlistedSites">
        <span class="md-list-item-text"  @click="toggleSidebar">Unlisted Sites</span>
      </router-link>
      <router-link v-if="isAdmin" tag="md-list-item" to="/admin/favPages">
        <span class="md-list-item-text"  @click="toggleSidebar">FavPages</span>
      </router-link>
      <router-link v-if="isAdmin" tag="md-list-item" :to="{ name: 'mostPopularSites' }">
        <span class="md-list-item-text"  @click="toggleSidebar">Most Popular Sites</span>
      </router-link>
    </md-list>
  </div>
</template>

<script>
import sidebarMixin from '@/mixins/sidebarMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';

export default {
  name: 'RecSidebar',
  mixins: [
    sidebarMixin,
    loggedInUserMixin,
  ],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      expandSingle: true,
    };
  },
  methods: {
    enableTour() {
      this.toggleSidebar();
      this.$tours.myTour.start();
    },
    home() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
  },
  computed: {
    tour() {
      return this.$store.getters.tour;
    },
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
<style lang="scss">
 .help-item {
   .md-list-item-text {
     color: #a5a5a5 !important;
   }
 }
@import '@/assets/scss/layout/rec-sidebar-mobile.scss';
</style>
