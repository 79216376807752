import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      sidebar: {
        settings: 'Settings',
        account: 'Account',
        billing: 'Billing',
        export: 'Export Faves',
      },
      userDashboard: 'User Dashboard',
      adminDashboard: 'Admin Dashboard',
      mail: 'hi@wibki.com',
      blogPost: 'Post',
      admins: 'Admins',
      usernameMinimumLength: 'The username should be longer than 3 characters',
      validUsername: 'Please provide a valid username address.',
      missingValue: 'Required field',
      logout: 'Log out',
      loginBtn: 'Log in',
      mostPopular: 'Most Popular',
      weekley: 'Weekly Faves',
      userCard: {
        privateProfile: 'Private profile',
        publicProfile: 'Public profile',
        favorite: 'favorite',
        bookmark: 'bookmark',
        share: 'share',
      },
      account: {
        upload: 'Upload a new Photo...',
        username: "Username",
        mail: 'E-mail',
        privateAccount: 'Keep my account private',
        notifications: 'Receive notifications',
        updates: 'Receive updates about Wibki',
        deleteAccount: 'Delete Account',
        text1: 'Visualize',
        text2: 'your',
        text3: 'Bookmarks',
      },
      bookmarklet: {
        title: 'Add a bookmark',
        titleRequired: 'Title Required',
        addNewTab: 'Add New Tab',
        addBookmark: 'Add bookmark',
        drag: 'Drag this Button to your Bookmarks Bar',
        install: 'To install the "Fave It" button:',
        installBookmarkBtn: 'To install the "Add it to your bookmarks" button:',
        installex1: 'Display your Bookmarks by clicking the Ctrl + Shift + B on your keyboard',
        installex2: 'Drag the "Fave It" button to your Bookmarks bar',
        installex3: 'When you are browsing the web, press the "Fave It" button to bookmark a website in your account',
        installex4: 'Drag the "Add it to your bookmarks" button to your Bookmarks bar',
        installex5: 'When you are browsing the web, press the "Add it to your bookmarks" button to bookmark a website in your account',
      },
      login: {
        title: 'Welcome back to',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        requiredField: 'Required field.',
        minLengthUsername: 'The username should be minimum 3 characters.',
        minLengthPassword: 'The password should be minimum 5 characters.',
        forgotUsername: 'Forgot username?',
        forgotPassword: 'Forgot password?',
        loginBtn: 'Login',
        noAccount: "Don't have an account?",
        getStarted: 'Get started',
        goToLogin: 'Go to Login Page',
        confirmedAccount: 'Account has been confirmed',
      },
      faves: {
        favesByCountryTitle: 'Faves By Country',
        hide: 'Hide',
        show: 'Show',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Add your own faves',
        cellTitle: 'Site Details',
        validUrl: 'Url is not valid!',
        delete: 'delete',
        logo: 'logo',
        save: 'save',
        mostPopularSiteTitle: 'Add new Site to Most Popular List',
        sitetitle: 'Site',
        position: 'Position',
        close: 'Close',
        tabDetails: 'Tab details',
        private: 'private',
        public: 'public',
        share: 'share',
        copy: 'copy link',
        settings: 'Setting',
        settingModalTitle: 'Add New Setting',
        key: 'Key',
        value: 'Value',
        description: 'Description',
        upgradePlan: 'Upgrade your plan to add more tabs',
        changePlan: 'See our plans'
      },
      forgotPassword: {
        title: 'Forgot Password',
        forgotBtn: 'FORGOT PASSWORD',
      },
      resetPassword: {
        title:'Reset Password',
        resetBtn: 'RESET PASSWORD',
        passwordMinLength: 'The password should be longer than 8 characters',
      },
      register: {
        title: 'Create your Wibki Account',
        email: 'Email',
        username: 'Username',
        password: 'Password',
        requiredField: 'Required field',
        registerBtn: 'Create account',
        haveAccount: 'Have an account?',
        signInBtn: 'Sign in',
        acceptTC: 'I accept',
        pleaseAcceptTC: 'Please accept Terms&Conditions',
        termsConditions: 'T&C',
        visualBookmarks: 'Effortless Organization',
        visualBookmarksTxt1: 'Simplify bookmarking with Wibki visual tool.',
        visualBookmarksTxt2: 'Our visual bookmarking tool allows you to add and manage all your favorite sites in one place.',
        discoverMore: 'Discover Amazing Websites',
        discoverMoreTxt: 'Explore a curated selection of awesome websites.',
        discoverMoreTxt1: 'Wibki offers a curated selection of awesome websites across a wide range of topics,',
        discoverMoreTxt2: 'so you can easily find something new to love.',
        faveIt: 'Save Your Favorites with Ease',
        faveItTxt: 'Save your links quickly with the "Fave It" button.',
        faveItTxt1: 'use Wibki "Fave It" button to quickly and easily save your favorite pages.',
        faveItTxt2: 'Access them anytime, anywhere.',
        everywhere: 'Your Bookmarks, Anywhere',
        everywhereTxt: 'Access your bookmarks on any device with Wibki.',
        everywhereTxt1: 'Our tool is available on any computer, smartphone, or tablet,',
        everywhereTxt2: 'so you can access your favorite sites no matter where you are.',
        invalidEmail: 'Email is not valid',
        minLengthUsername: 'The username should be minimum 3 characters.',
        minLengthPassword: 'The password should be minimum 5 characters.',
      },
      terms: {
        termsOfUse: 'Terms of use',
        amfInc: 'Wibki Inc.',
        // eslint-disable-next-line max-len
        followingConditions: 'Wibki provides its services to you subject to the following conditions. By visiting wibki.com, you accept these conditions. Please read them carefully. Wibki Inc. is not responsible for the content or the privacy policies of websites to which it may link',
        privacyPolicy: 'Privacy Policy',
        licensing: 'Licensing',
        // eslint-disable-next-line max-len
        licensingTxt: 'The logos that appear on Wibki are of a company, organization, item, product or event, and are protected by copyright and/or trademark. It is believed that the use of low-resolution images of logos to illustrate the Company, organization, item, product or event in question, to use a visual link directly to the Company, organization, item, or event associated with the respective links/logo, qualifies as fair use under Copyright Law. Any featured companies, organizations, items or events on the wibki.com website that wish to be removed from Wibki, may contact us at hi@wibki.com and we shall remove these upon request.',
        terms1: 'We reserve the right to modify or terminate the Wibki service for any reason, without notice at any time.',
        terms2: 'You must be at least 13 years or older to sign up for wibki.com.',
        // eslint-disable-next-line max-len
        terms3: 'Initiating or taking part in any malicious behavior towards Wibki Inc., wibki.com, its Users or any third party via the Company\'s website or under your Wibki account is strictly forbidden. Any malicious and/or obtrusive actions or behavior on part of the User, as judged by Wibki Inc., shall result in the prompt termination of your Wibki account until further notice.',
        // eslint-disable-next-line max-len
        terms4: 'Creating, sharing and/or submitting irrelevant or malicious email messages or \'spam\' to any Wibki members is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms5: 'Any activity that takes place or is initiated under your Wibki User name and/or account, including any content exchanged between and shared with other Users or any other third parties is under your own responsibility.',
        terms6: 'Transmitting any worms, viruses or any malicious code is strictly forbidden.',
        terms7: 'It is your responsibility as a User to safeguard your User name and password.',
        // eslint-disable-next-line max-len
        terms8: 'It is strictly forbidden to use the Wibki service for any illegal and/or unauthorized purpose. International Users agree to comply with all local laws associated with online conduct and appropriate content.',
        // eslint-disable-next-line max-len
        terms9: 'Violating any laws in your jurisdiction (including but not limited to copyright laws) while using the Wibki service is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms10: 'Users are the sole persons responsible for online conduct, the links and sites they choose to add to their account and any derived content and information thereof. The choice of sites added to the personalized Wibki feature is entirely up to the User, and Wibki Inc. and/or Wibki shall not be responsible or held accountable for any inappropriate content as a result.',
        // eslint-disable-next-line max-len
        terms11: 'Making changes to, adapting, hacking or making use of the Wibki service for any purpose or reason other than those described in this document or anywhere else on the website, including any false representation of Wibki and/or association with Wibki is strictly forbidden. Offenders shall be prosecuted accordingly.',
        terms12: 'Wibki reserve the right to refuse service to anyone for any reason at any time, as directed by any applicable laws.',
        terms13: 'Wibki may, but is not obligated to, delete accounts and/or content that we deem inappropriate, malicious, illegal, obtrusive, harmful, abusive or otherwise in violation of any of these Terms of Use.',
        terms14: 'Wibki reserves the right to change or delete User names for any reason as it sees fit, without any obligation to provide the respective Users with any notice.',
        terms15: 'Wibki reserves the right to change or delete User names on behalf of companies, businesses or individuals, for which those User names are a source of legal, registered trademark or intellectual property dispute and/or claim.',
        terms16: 'You understand and agree that by adding a site to your Wibki personalized home page you do not own, neither entirely nor partially, or maintain any copyright of the chosen site\'s trademark.',
        termsViolation: 'Violation of or failure to adhere to any of these agreements shall result in the prompt termination of your Wibki account until further notice. Although Wibki does not allow such conduct and content on its site, you, the User, are aware of, understand and agree that Wibki can not and will not be held responsible for the nature or quality of the content posted by Users on the web site and you, the User, may be exposed to such materials. Using the Wibki service is under your sole responsibility and at your own risk.',
        termsReservedRight: 'Wibki reserves its right to modify any part or all of these Terms of Use at any time and the reason for and onset of such modifications is up to the sole discretion of Wibki.',
        games: 'Games',
        gamesTerms1: 'Wibki holds no responsibility for any of the games featured on Wibki or content thereof. All of the featured games were neither developed nor created by Wibki.',
        gamesTerms2: 'Wibki can not be held reliable for the content provided by and within each game.',
        gamesTerms3: 'Games featured on Wibki may contain violence, suggestive themes, crude humor, blood and gore, simulated gambling and/or infrequent use of strong language. By proceeding to play the game you agree that you have understood the respective game\'s disclaimers, if any, or any other warnings provided by the games\' creators.',
        gamesTerms4: 'Wibki is not responsible for any vulgar, offensive or abusive language, or any other inappropriate expressive language which may offend individuals or groups in general.',
        gamesTerms5: 'All visitors may enter and play multi-player games at their own risk.',
        gamesTerms6: 'Games which are featured on Wibki are copyrighted property of their respective owner/developer. An owner/developer of a game who wishes to be removed from Wibki may contact us at hi@wibki.com and we shall remove it upon request.',
        disclaimerWarrantyLiability: 'Disclaimer of Warranties and Limitation of Liability',
        discWarrLiTxt1: 'THIS SITE IS PROVIDED BY Wibki INC. ON AN "AS IS" AND "AS AVAILABLE" BASIS. wibki.com MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPLICITLY, IMPLICITLY OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS OR PRODUCTS INCLUDED ON THIS SITE. YOU HEREBY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR OWN RISK.',
        discWarrLiTxt2: 'TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, wibki.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. wibki.com DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM wibki.com ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. wibki.com WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.',
        discWarrLiTxt3: 'CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.',
        other: 'Other',
        otherTxt: 'Wibki and you are independent entities, and nothing in the Wibki Terms, or via use of Wibki , will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between Wibki and you. These Wibki Terms supersede any previous agreement related to your use of Wibki and represent the entire agreement between Wibki and you with regard to your use of Wibki.',
        yourFeedback: 'Your Feedback',
        yourInput: 'Wibki welcomes your continuous input regarding our Terms of Use & Privacy Policy or our provided services. You may send us your comments and responses to hi@wibki.com'
      },
      privacy: {
        statement: 'Privacy statement of Wibki Inc.',
        info: 'Information regarding the Wibki organization and web site:',
        privacyInfo: 'Wibki Inc. recognizes that your privacy is very important. Please read the information below to learn more about our privacy policy guidelines. Since this is a website in progress with continual additions and expansions, and because Internet technologies are constantly evolving, these guidelines are subject to change. It is up to Wibki\' discretion to make any changes as it sees fit, and any such changes shall be posted on this page.',
        advised: 'You are advised to consult this Privacy Policy regularly for any changes.',
        notResponsible: 'Wibki Inc. is not responsible for the content or the privacy policies of websites to which the site may link.',
        principal: 'Our principal activity entails a visual portal and bookmark manager.',
        cover: 'Our privacy policy covers Wibki Inc. and its website:',
        organisationName: 'Organisation Name',
        city: 'City',
        country: 'Country',
        phone: 'Phone number',
        webSites: 'Web Site(s)',
        atAMF: 'At Wibki, we recognize that your privacy and that of your personal information is important. Below we provide information on what types of personal information we receive and maintain in our company\'s records when you use/visit Wibki, and how we safeguard that information.',
        collect: 'Wibki collects and uses personally identifiable information for the following reasons and purposes only: providing users with the services made available through the wibki.com including, but not limited to the service, to communicate with users, and to manage users\' registered accounts, if and when applicable.',
        logFiles: 'Log Files',
        logFilesTxt1: 'As with most other websites, our servers automatically record information that your browser sends whenever you visit a website ("Log Data" ). The information in the log files may include your IP address (Internet Protocol) , your ISP (Internet Service Provider, such as AOL or Shaw Cable), the browser through which you visit our site (such as Internet Explorer or Firefox), the time at which you visited our site, and which pages you visited within the entire Wibki site.',
        logFilesTxt2: 'We use Log Data to monitor the use of the Site and of our Service. We do not associate your IP address with any other personally identifiable information to identify you personally, except in case of violation of the Terms of Use.',
        cookiesBeacons: 'Cookies and Web Beacons',
        cbTxt1: 'Wibki uses cookies to store information such as your personal preferences when you visit our site. If you are not familiar with what cookies are, here is a brief explanation: A cookie is a small data file many websites (wibki.com included) carry to your computer\'s hard disk. Wibki does not use cookies to collect users\' personally identifiable information. We use cookies as an improvement tool for the Company, the wibki.com website and our offered services. More specifically, we use cookies as data information regarding users\' site preferences and navigation behavior, and website traffic and referred visitor statistics (navigation between wibki.com and other websites).',
        cbTxt2: 'A session cookie makes certain site features and services possible, yet is only a session long, meaning once you log out or turn your computer off, the cookie ceases its activity and is removed from your computer. A persistent cookie stays in your computer despite disconnecting from your browser, and is used by the browser in re-visits to the web site. You can remove persistent cookies by consulting your browser support/help service.',
        cbTxt3: 'Most Internet browsers automatically accept cookies. You can chose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or manage your preferences in programs such as Norton Internet Security. However, this can affect how you are able to interact with our site as well as other websites. One of the consequences might be users\' inability to log in to services or programs, such as logging into forums or accounts.',
        ads: 'Advertisement',
        adsTxt: 'Wibki uses third party advertisements on Wibki to support our web site. Some of these advertisers may use web technologies such as cookies and web beacons on our site. These technological tools send participating advertisers (such as Google through the Google AdSense program) information such as your IP address, your ISP , the browser you used to visit our site, and in some cases, information regarding your installed Flash software. This is generally used for geo-targeting purposes (showing New York real estate ads to someone in New York, for example) or for showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).',
        infoCollect: 'Information Collected by Wibki',
        infoCollectTxt1: 'Although using wibki.com is free for everyone, users need to register in order to take advantage of certain services and features on wibki.com, such as the MiniFaves feature. As a registered user, you will be asked to provide us with certain personally identifiable information. Such information may include your computer\'s IP address, user name, password, email address, geographic location and local time, phone number, and any other details you choose to submit to us or include in your personalized MiniFaves page.',
        infoCollectTxt2: 'By adding your own choice of sites to your personalized Wibki homepage, you allow Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        useOfContactInfo: 'Use of Contact Information',
        useOfContactInfoTxt: 'We may use your contact information to send you updates and promotions of current and future Wibki features and offered services. Should you decide to discontinue the recipt of such information, you are welcome to unsubscibe via our updates and/or promotion messages sent to your email.',
        serviceProviders: 'Service Providers',
        serviceProvidersTxt: 'Wibki has agreements with certain external service providers and other reliable and safe third parties for the Company and website\'s managment and maintenance. These include but are not limited to hosting and maintenance providers, database storage and management services, and marketing projects. As an wibki.com\'s user, your personally identifiable information may be shared with these third parties on a limited basis, meaning only for the purpose of performing and providing wibki.com\'s services and features. In addition, sharing that personally identifiable information shall occur, if at all, only after those third parties have made an explicit obligation to safeguard your privacy and ensure the security of your data.',
        confidentiality: 'Confidentiality',
        confidentialityTxt: 'Feedback and other comments and statements that you chose to submit to Wibki by any means - whether by e-mail to Wibki Customer Service, telephone, letter, or any other communication method - shall be considered nonconfidential. Wibki is free to use such information on an unrestricted basis.',
        useInfo: 'The ways in which we may use your information',
        useInfoTxt1: 'We use users\' collected information to improve our marketing and promotional efforts; to analyze site usage with statistics and data; to improve our content and product offerings, and; to customize the Wibki web site\'s content, layout and services, for the benefit of our users. We may use your collected information to service your account with us, including but not limited to investigating problems, resolving disputes and enforcing agreements with us. We do not sell, rent, trade or exchange any personally-identifying information of our users. We may share certain aggregate information based on analysis of collected information with our partners, customers, advertisers or potential users. We may use your collected information to execute marketing campaigns, promotions or advertising messages on behalf of third parties. Information submitted through the \'Tell a Friend\' feature is collected and used in the mail which is sent to the referred person or \'friend\' who was listed in the submission.',
        useInfoTxt2: 'By posting content, you are granting permission to Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        linksToOther: 'Links to other sites',
        linksToOtherTxt: 'Although Wibki provides numerous links to websites, this action is by no means any endorsement, advertisement for profit, authorization or representation of our affiliation with those third parties or featured sites, excluding sites and parties with which Wibki has a contracted agreement. Wibki has no control over any of the sites we feature anywhere on the website. Featured sites on Wibki may use cookies of their own or other files on your computer, collect data or solicit personally identifiable information from you. Other sites comply with different rules regarding the use or disclosure of the personally identifiable information submitted to them by users. Please consult the privacy policies and/or statements of the other websites you visit.',
        policyChildren: 'Wibki\' Policy Regarding Children',
        policyChildrenTxt: 'Only users at least 13 years old or older may use wibki.com. Children under 13 are welcome to use the website under the supervision of their parents, legal gurdian or other authorized adult. However, Children may not sign or provide any perosnally identifiable information to wibki.com. If you are a parent or legal guardian who has learned that the child under your care is or has provided us with personally identifiable information without your knowledge and/or concent, contact us at hi@wibki.com. wibki.com does not knowingly collect personally identifiable information from children under 13. Should we learn of such a case, his or her information shall be removed immediately from our records.',
        deleteUsers: 'Delete Your Account',
        deleteUsersTxt: 'To delete your account and remove your content, please follow these step:',
        deleteUsersTxt1: '1. Click on the settings tab located at the top right of the site.',
        deleteUsersTxt2: '2. Locate the "Delete Account" button on the main page.',
        deleteUsersTxt3: '3. Click on the button and follow the prompts to complete the deletion process.',
        deleteUsersTxt4: 'If you have any issues locating your account or have any requests regarding deletion, please send an email to hi@wibki.com and we will assist you. Note that deleting your account is permanent and cannot be undone. Please make sure that you have downloaded any critical data or content before proceeding with account deletion.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'Wibki is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of Wibki\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With Wibki, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the Wibki team members. This way, Wibki acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. Wibki has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the Wibki concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The Wibki\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard Wibki go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of Wibki\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making Wibki your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'The Bookmark',
        saveBookmark: 'Easily Save Your Faves',
      },
      contact: {
        title: 'Contact Us',
        subtitle1: 'Your opinion means a great deal to us. If you have a comment, suggestion, complaint or recommendation of a site/category, Please mail us at',
        subtitle2: 'or fill out the form below.',
        checkboxTitle: 'How did you hear about us?',
        checkbox: {
          search: 'Search',
          referringSite: 'Reffering Site',
          tv: 'Television',
          radio: 'Radio',
          newspaper: 'Newspaper',
          world: 'World Of Mouth'
        },
      },
      footer: {
        titleSection1: 'Wibki',
        aboutUs: 'About Us',
        join: 'Join',
        tellAFriend: 'Tell A Friend',
        press: 'Press',
        blog: 'Blog',
        adminArea: "Admin Area",
        support: 'Support',
        faq: 'FAQ',
        wantMore: 'Want More?',
        contactUs: 'Contact Us',
        oldWebsite: 'Old Website',
        downloadApp: "Download the App",
        privacy: 'Privacy Policy',
        terms: 'Terms of Use',
        rights: 'All rights reserved.',
      },
      navbar: {
        TYPE_TOP_FAVES: 'Top Faves',
        TYPE_GAMES: 'Top Games',
        TYPE_BLOGS: 'Top Blogs',
        TYPE_CATEGORY: 'Categories',
        shop: 'Shop',
        sports: 'Sports',
        shopping: 'Shopping',
        apparel: 'Apparel',
        homeElectronics: 'Home & Electronics',
        discover: 'Discover',
        weeklyFaves: 'Weekly Faves',
        weeklyGames: 'Weekly Games',
        favesByCountry: 'Faves By Country',
        blog: 'Blog',
        settings: 'Settings',
        myFaves: 'MyFaves',
        gifts: 'Gifts',
        entertainment: 'entertainment',
        apps: 'Apps',
        instagram: 'Instagram',
        giftguide: 'Gift Guide',
        interests: 'Add Interests',
        faveButton: 'Fave It Button',
        faq: 'FAQ',
        sideBarHome: 'Navigation',
        home: 'Home',
        profile: 'Profile',
        adminMenu: 'Admin Menu',
        categories: 'Categories',
        blogs: 'Blogs',
        biz: 'Business',
        edu: 'Education',
        ent: 'Entertainment',
        kids: 'Kids',
        npo: 'NPO',
        travel: 'Travel',
        tvonline: 'Tv Online',
        xmas: 'Xmas',
      },
    },
    ru: {
      sidebar: {
        settings: 'настройки',
        account: 'учетная запись',
        billing: 'выставление счетов',
        export: 'экспортные фавориты',
      },
      userDashboard: 'панель пользователя',
      adminDashboard: 'панель администратора',
      mail: 'hi@wibki.com',
      blogPost: 'Post',
      admins: 'админы',
      missingValue: 'Обязательное поле',
      usernameMinimumLength: 'Имя пользователя должно быть длиннее 3 символов.',
      validUsername: 'Пожалуйста, укажите действующий адрес пользователя.',
      logout: 'Выйти',
      mostPopular: 'Самый популярный',
      weekley: 'Еженедельные лица',
      userCard: {
        privateProfile: 'Личный профиль',
        publicProfile: 'Общедоступный профиль',
        favorite: 'любимый',
        bookmark: 'закладка',
        share: 'Поделиться',
      },
      account: {
        upload: 'Загрузить новую фотографию...',
        username: "Имя пользователя",
        mail: 'Эл. почта',
        privateAccount: 'Держите мою учетную запись приватной',
        notifications: 'Получать уведомления',
        updates: 'Получать обновления обо всех моих любимых',
        deleteAccount: 'Удалить аккаунт',
        text1: 'Визуализировать',
        text2: 'ваш',
        text3: 'Закладки',
      },
      bookmarklet: {
        title: 'Добавить закладку',
        titleRequired: 'Требуется название',
        addNewTab: 'Добавить новую вкладку',
        addBookmark: 'Добавить закладку',
        drag: 'Перетащите эту кнопку на панель закладок',
        install: 'Чтобы установить кнопку «Добавить в избранное»:',
        installex1: 'Отобразите свои закладки, нажав Ctrl + Shift + B на клавиатуре',
        installex2: 'Перетащите кнопку «Добавить в избранное» на панель закладок.',
        installex3: 'При просмотре веб-страниц нажмите кнопку «Добавить в избранное», чтобы добавить веб-сайт в закладки в своей учетной записи.',
      },
      login: {
        title: 'Добро пожаловать обратно в',
        email: 'Эл. адрес',
        username: 'Username',
        password: 'имя пользователя',
        requiredField: 'Обязательное поле.',
        minLengthUsername: 'Имя пользователя должно состоять минимум из 3 символов..',
        minLengthPassword: 'Пароль должен состоять минимум из 5 символов..',
        forgotUsername: 'забыл имя пользователя?',
        forgotPassword: 'забыл пароль?',
        loginBtn: 'Авторизоваться',
        noAccount: "Нет учетной записи?",
        getStarted: 'Начать',
        goToLogin: 'Перейти на страницу входа',
        confirmedAccount: 'Аккаунт подтвержден',
      },
      faves: {
        favesByCountryTitle: 'Любимые страны',
        hide: 'Скрывать',
        show: 'Показать',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Добавьте своих любимых',
        cellTitle: 'Детали сайта',
        validUrl: 'URL недействителен!',
        delete: 'удалять',
        logo: 'логотип',
        save: 'спасти',
        mostPopularSiteTitle: 'Добавить новый сайт в список самых популярных',
        sitetitle: 'Сайт',
        position: 'Позиция',
        close: 'Закрывать',
        tabDetails: 'Детали вкладки',
        private: 'частный',
        public: 'общественный',
        share: 'Поделиться',
        copy: 'копировать ссылку',
      },
      forgotPassword: {
        title: 'Забыл пароль',
        forgotBtn: 'ЗАБЫЛ ПАРОЛЬ',
      },
      resetPassword: {
        title:'Сброс пароля',
        resetBtn: 'СБРОС ПАРОЛЯ',
        passwordMinLength: 'Пароль должен быть длиннее 8 символов.',
      },
      register: {
        title: 'Создайте свою учетную запись Wibki',
        email: 'Эл. адрес',
        username: 'Имя пользователя',
        password: 'Пароль',
        requiredField: 'Обязательное поле',
        registerBtn: 'Создать аккаунт',
        haveAccount: 'Иметь аккаунт?',
        signInBtn: 'Войти',
        acceptTC: 'Я принимаю Я согласен',
        pleaseAcceptTC: 'Пожалуйста, примите Условия использования',
        termsConditions: 'T&C',
        visualBookmarks: 'Визуальные закладки',
        visualBookmarksTxt: 'Добавляйте и управляйте всеми своими закладками под одной крышей.',
        discoverMore: 'Узнайте больше',
        discoverMoreTxt: 'Найдите множество отличных веб-сайтов на любую тему.',
        faveIt: 'Fave It',
        faveItTxt: 'С легкостью сохраняйте избранное, используя кнопку «Добавить в избранное».',
        everywhere: 'Где угодно',
        everywhereTxt: 'Используйте его везде. На любом компьютере, смартфоне или планшете.',
      },
      terms: {
        termsOfUse: 'Terms of use',
        amfInc: 'Wibki Inc.',
        // eslint-disable-next-line max-len
        followingConditions: 'Wibki provides its services to you subject to the following conditions. By visiting wibki.com, you accept these conditions. Please read them carefully. Wibki Inc. is not responsible for the content or the privacy policies of websites to which it may link',
        privacyPolicy: 'Privacy Policy',
        licensing: 'Licensing',
        // eslint-disable-next-line max-len
        licensingTxt: 'The logos that appear on Wibki are of a company, organization, item, product or event, and are protected by copyright and/or trademark. It is believed that the use of low-resolution images of logos to illustrate the Company, organization, item, product or event in question, to use a visual link directly to the Company, organization, item, or event associated with the respective links/logo, qualifies as fair use under Copyright Law. Any featured companies, organizations, items or events on the wibki.com website that wish to be removed from Wibki, may contact us at hi@wibki.com and we shall remove these upon request.',
        terms1: 'We reserve the right to modify or terminate the Wibki service for any reason, without notice at any time.',
        terms2: 'You must be at least 13 years or older to sign up for wibki.com.',
        // eslint-disable-next-line max-len
        terms3: 'Initiating or taking part in any malicious behavior towards Wibki Inc., wibki.com, its Users or any third party via the Company\'s website or under your Wibki account is strictly forbidden. Any malicious and/or obtrusive actions or behavior on part of the User, as judged by Wibki Inc., shall result in the prompt termination of your Wibki account until further notice.',
        // eslint-disable-next-line max-len
        terms4: 'Creating, sharing and/or submitting irrelevant or malicious email messages or \'spam\' to any Wibki members is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms5: 'Any activity that takes place or is initiated under your Wibki User name and/or account, including any content exchanged between and shared with other Users or any other third parties is under your own responsibility.',
        terms6: 'Transmitting any worms, viruses or any malicious code is strictly forbidden.',
        terms7: 'It is your responsibility as a User to safeguard your User name and password.',
        // eslint-disable-next-line max-len
        terms8: 'It is strictly forbidden to use the Wibki service for any illegal and/or unauthorized purpose. International Users agree to comply with all local laws associated with online conduct and appropriate content.',
        // eslint-disable-next-line max-len
        terms9: 'Violating any laws in your jurisdiction (including but not limited to copyright laws) while using the Wibki service is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms10: 'Users are the sole persons responsible for online conduct, the links and sites they choose to add to their account and any derived content and information thereof. The choice of sites added to the personalized Wibki feature is entirely up to the User, and Wibki Inc. and/or Wibki shall not be responsible or held accountable for any inappropriate content as a result.',
        // eslint-disable-next-line max-len
        terms11: 'Making changes to, adapting, hacking or making use of the Wibki service for any purpose or reason other than those described in this document or anywhere else on the website, including any false representation of Wibki and/or association with Wibki is strictly forbidden. Offenders shall be prosecuted accordingly.',
        terms12: 'Wibki reserve the right to refuse service to anyone for any reason at any time, as directed by any applicable laws.',
        terms13: 'Wibki may, but is not obligated to, delete accounts and/or content that we deem inappropriate, malicious, illegal, obtrusive, harmful, abusive or otherwise in violation of any of these Terms of Use.',
        terms14: 'Wibki reserves the right to change or delete User names for any reason as it sees fit, without any obligation to provide the respective Users with any notice.',
        terms15: 'Wibki reserves the right to change or delete User names on behalf of companies, businesses or individuals, for which those User names are a source of legal, registered trademark or intellectual property dispute and/or claim.',
        terms16: 'You understand and agree that by adding a site to your Wibki personalized home page you do not own, neither entirely nor partially, or maintain any copyright of the chosen site\'s trademark.',
        termsViolation: 'Violation of or failure to adhere to any of these agreements shall result in the prompt termination of your Wibki account until further notice. Although Wibki does not allow such conduct and content on its site, you, the User, are aware of, understand and agree that Wibki can not and will not be held responsible for the nature or quality of the content posted by Users on the web site and you, the User, may be exposed to such materials. Using the Wibki service is under your sole responsibility and at your own risk.',
        termsReservedRight: 'Wibki reserves its right to modify any part or all of these Terms of Use at any time and the reason for and onset of such modifications is up to the sole discretion of Wibki.',
        games: 'Games',
        gamesTerms1: 'Wibki holds no responsibility for any of the games featured on Wibki or content thereof. All of the featured games were neither developed nor created by Wibki.',
        gamesTerms2: 'Wibki can not be held reliable for the content provided by and within each game.',
        gamesTerms3: 'Games featured on Wibki may contain violence, suggestive themes, crude humor, blood and gore, simulated gambling and/or infrequent use of strong language. By proceeding to play the game you agree that you have understood the respective game\'s disclaimers, if any, or any other warnings provided by the games\' creators.',
        gamesTerms4: 'Wibki is not responsible for any vulgar, offensive or abusive language, or any other inappropriate expressive language which may offend individuals or groups in general.',
        gamesTerms5: 'All visitors may enter and play multi-player games at their own risk.',
        gamesTerms6: 'Games which are featured on Wibki are copyrighted property of their respective owner/developer. An owner/developer of a game who wishes to be removed from Wibki may contact us at hi@wibki.com and we shall remove it upon request.',
        disclaimerWarrantyLiability: 'Disclaimer of Warranties and Limitation of Liability',
        discWarrLiTxt1: 'THIS SITE IS PROVIDED BY Wibki INC. ON AN "AS IS" AND "AS AVAILABLE" BASIS. wibki.com MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPLICITLY, IMPLICITLY OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS OR PRODUCTS INCLUDED ON THIS SITE. YOU HEREBY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR OWN RISK.',
        discWarrLiTxt2: 'TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, wibki.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. wibki.com DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM wibki.com ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. wibki.com WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.',
        discWarrLiTxt3: 'CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.',
        other: 'Other',
        otherTxt: 'Wibki and you are independent entities, and nothing in the Wibki Terms, or via use of Wibki , will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between Wibki and you. These Wibki Terms supersede any previous agreement related to your use of Wibki and represent the entire agreement between Wibki and you with regard to your use of Wibki.',
        yourFeedback: 'Your Feedback',
        yourInput: 'Wibki welcomes your continuous input regarding our Terms of Use & Privacy Policy or our provided services. You may send us your comments and responses to hi@wibki.com'
      },
      privacy: {
        statement: 'Privacy statement of Wibki Inc.',
        info: 'Information regarding the Wibki organization and web site:',
        privacyInfo: 'Wibki Inc. recognizes that your privacy is very important. Please read the information below to learn more about our privacy policy guidelines. Since this is a website in progress with continual additions and expansions, and because Internet technologies are constantly evolving, these guidelines are subject to change. It is up to Wibki\' discretion to make any changes as it sees fit, and any such changes shall be posted on this page.',
        advised: 'You are advised to consult this Privacy Policy regularly for any changes.',
        notResponsible: 'Wibki Inc. is not responsible for the content or the privacy policies of websites to which the site may link.',
        principal: 'Our principal activity entails a visual portal and bookmark manager.',
        cover: 'Our privacy policy covers Wibki Inc. and its website:',
        organisationName: 'Organisation Name',
        city: 'City',
        country: 'Country',
        phone: 'Phone number',
        webSites: 'Web Site(s)',
        atAMF: 'At Wibki, we recognize that your privacy and that of your personal information is important. Below we provide information on what types of personal information we receive and maintain in our company\'s records when you use/visit Wibki, and how we safeguard that information.',
        collect: 'Wibki collects and uses personally identifiable information for the following reasons and purposes only: providing users with the services made available through the wibki.com including, but not limited to the service, to communicate with users, and to manage users\' registered accounts, if and when applicable.',
        logFiles: 'Log Files',
        logFilesTxt1: 'As with most other websites, our servers automatically record information that your browser sends whenever you visit a website ("Log Data" ). The information in the log files may include your IP address (Internet Protocol) , your ISP (Internet Service Provider, such as AOL or Shaw Cable), the browser through which you visit our site (such as Internet Explorer or Firefox), the time at which you visited our site, and which pages you visited within the entire Wibki site.',
        logFilesTxt2: 'We use Log Data to monitor the use of the Site and of our Service. We do not associate your IP address with any other personally identifiable information to identify you personally, except in case of violation of the Terms of Use.',
        cookiesBeacons: 'Cookies and Web Beacons',
        cbTxt1: 'Wibki uses cookies to store information such as your personal preferences when you visit our site. If you are not familiar with what cookies are, here is a brief explanation: A cookie is a small data file many websites (wibki.com included) carry to your computer\'s hard disk. Wibki does not use cookies to collect users\' personally identifiable information. We use cookies as an improvement tool for the Company, the wibki.com website and our offered services. More specifically, we use cookies as data information regarding users\' site preferences and navigation behavior, and website traffic and referred visitor statistics (navigation between wibki.com and other websites).',
        cbTxt2: 'A session cookie makes certain site features and services possible, yet is only a session long, meaning once you log out or turn your computer off, the cookie ceases its activity and is removed from your computer. A persistent cookie stays in your computer despite disconnecting from your browser, and is used by the browser in re-visits to the web site. You can remove persistent cookies by consulting your browser support/help service.',
        cbTxt3: 'Most Internet browsers automatically accept cookies. You can chose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or manage your preferences in programs such as Norton Internet Security. However, this can affect how you are able to interact with our site as well as other websites. One of the consequences might be users\' inability to log in to services or programs, such as logging into forums or accounts.',
        ads: 'Advertisement',
        adsTxt: 'Wibki uses third party advertisements on Wibki to support our web site. Some of these advertisers may use web technologies such as cookies and web beacons on our site. These technological tools send participating advertisers (such as Google through the Google AdSense program) information such as your IP address, your ISP , the browser you used to visit our site, and in some cases, information regarding your installed Flash software. This is generally used for geo-targeting purposes (showing New York real estate ads to someone in New York, for example) or for showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).',
        infoCollect: 'Information Collected by Wibki',
        infoCollectTxt1: 'Although using wibki.com is free for everyone, users need to register in order to take advantage of certain services and features on wibki.com, such as the MiniFaves feature. As a registered user, you will be asked to provide us with certain personally identifiable information. Such information may include your computer\'s IP address, user name, password, email address, geographic location and local time, phone number, and any other details you choose to submit to us or include in your personalized MiniFaves page.',
        infoCollectTxt2: 'By adding your own choice of sites to your personalized Wibki homepage, you allow Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        useOfContactInfo: 'Use of Contact Information',
        useOfContactInfoTxt: 'We may use your contact information to send you updates and promotions of current and future Wibki features and offered services. Should you decide to discontinue the recipt of such information, you are welcome to unsubscibe via our updates and/or promotion messages sent to your email.',
        serviceProviders: 'Service Providers',
        serviceProvidersTxt: 'Wibki has agreements with certain external service providers and other reliable and safe third parties for the Company and website\'s managment and maintenance. These include but are not limited to hosting and maintenance providers, database storage and management services, and marketing projects. As an wibki.com\'s user, your personally identifiable information may be shared with these third parties on a limited basis, meaning only for the purpose of performing and providing wibki.com\'s services and features. In addition, sharing that personally identifiable information shall occur, if at all, only after those third parties have made an explicit obligation to safeguard your privacy and ensure the security of your data.',
        confidentiality: 'Confidentiality',
        confidentialityTxt: 'Feedback and other comments and statements that you chose to submit to Wibki by any means - whether by e-mail to Wibki Customer Service, telephone, letter, or any other communication method - shall be considered nonconfidential. Wibki is free to use such information on an unrestricted basis.',
        useInfo: 'The ways in which we may use your information',
        useInfoTxt1: 'We use users\' collected information to improve our marketing and promotional efforts; to analyze site usage with statistics and data; to improve our content and product offerings, and; to customize the Wibki web site\'s content, layout and services, for the benefit of our users. We may use your collected information to service your account with us, including but not limited to investigating problems, resolving disputes and enforcing agreements with us. We do not sell, rent, trade or exchange any personally-identifying information of our users. We may share certain aggregate information based on analysis of collected information with our partners, customers, advertisers or potential users. We may use your collected information to execute marketing campaigns, promotions or advertising messages on behalf of third parties. Information submitted through the \'Tell a Friend\' feature is collected and used in the mail which is sent to the referred person or \'friend\' who was listed in the submission.',
        useInfoTxt2: 'By posting content, you are granting permission to Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        linksToOther: 'Links to other sites',
        linksToOtherTxt: 'Although Wibki provides numerous links to websites, this action is by no means any endorsement, advertisement for profit, authorization or representation of our affiliation with those third parties or featured sites, excluding sites and parties with which Wibki has a contracted agreement. Wibki has no control over any of the sites we feature anywhere on the website. Featured sites on Wibki may use cookies of their own or other files on your computer, collect data or solicit personally identifiable information from you. Other sites comply with different rules regarding the use or disclosure of the personally identifiable information submitted to them by users. Please consult the privacy policies and/or statements of the other websites you visit.',
        policyChildren: 'Wibki\' Policy Regarding Children',
        policyChildrenTxt: 'Only users at least 13 years old or older may use wibki.com. Children under 13 are welcome to use the website under the supervision of their parents, legal gurdian or other authorized adult. However, Children may not sign or provide any perosnally identifiable information to wibki.com. If you are a parent or legal guardian who has learned that the child under your care is or has provided us with personally identifiable information without your knowledge and/or concent, contact us at hi@wibki.com. wibki.com does not knowingly collect personally identifiable information from children under 13. Should we learn of such a case, his or her information shall be removed immediately from our records.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'Wibki is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of Wibki\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With Wibki, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the Wibki team members. This way, Wibki acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. Wibki has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the Wibki concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The Wibki\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard Wibki go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of Wibki\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making Wibki your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'Закладка',
        saveBookmark: 'Легко сохранять свои любимые',
      },
      contact: {
        title: 'Связаться с нами',
        subtitle1: 'Ваше мнение очень важно для нас. Если у вас есть комментарий, предложение, жалоба или рекомендация сайта / категории, напишите нам по адресу',
        subtitle2: 'или заполните форму ниже.',
        checkboxTitle: 'Откуда вы узнали о нас?',
        checkbox: {
          search: 'Поиск',
          referringSite: 'Ссылающийся сайт',
          tv: 'Телевидение',
          radio: 'Радио',
          newspaper: 'Газета',
          world: 'Мир рта'
        },
      },
      footer: {
        titleSection1: 'Wibki',
        aboutUs: 'О нас',
        join: 'Присоединиться',
        tellAFriend: 'Рассказать другу',
        press: 'Нажмите',
        blog: 'Блог',
        adminArea: "Админка",
        support: 'Служба поддержки',
        faq: 'FAQ',
        wantMore: 'Хочу больше?',
        contactUs: 'Связаться с нами',
        oldWebsite: 'Old Website',
        downloadApp: "Скачать приложение",
        privacy: 'Политика конфиденциальности',
        terms: 'Условия эксплуатации',
        rights: 'Все права защищены.',
      },
      navbar: {
        topFaves: 'Top Faves',
        shop: 'Магазин',
        shopping: 'Покупка',
        apparel: 'Одежда',
        homeElectronics: 'Дом и электроника',
        discover: 'Обнаружить',
        weeklyFaves: 'Еженедельные избранное',
        weeklyGames: 'Еженедельные игры',
        favesByCountry: 'Любимые страны',
        blog: 'Блог',
        settings: 'Настройки',
        myFaves: 'Мои любимые',
        interests: 'Добавить интересы',
        faveButton: 'Кнопка Fave It',
        faq: 'FAQ',
        sideBarHome: 'Навигация',
        home: 'Дом',
        profile: 'Профиль',
        adminMenu: 'Меню администратора',
      },
    },
    es: {
      sidebar: {
        settings: 'Ajustes',
        account: 'La Cuenta',
        billing: 'La facturación',
        export: 'Exportar Favoritos',
      },
      userDashboard: 'Panel de Usuario',
      adminDashboard: 'Panel de Administración',
      mail: 'hi@wibki.com',
      blogPost: 'Post',
      admins: 'Administradores',
      missingValue: 'Campo requerido',
      usernameMinimumLength: 'El nombre de usuario debe tener más de 3 caracteres',
      validUsername: 'Proporcione una dirección de nombre de usuario válida.',
      logout: 'Cerrar sesión',
      mostPopular: 'Most Popular',
      weekley: 'Favoritos semanales',
      userCard: {
        privateProfile: 'Perfil privado',
        publicProfile: 'Perfil público',
        favorite: 'favorito',
        bookmark: 'marcador',
        share: 'Cuota',
      },
      account: {
        upload: 'Cargar una nueva foto ...',
        username: "Nombre de usuario",
        mail: 'Correo electrónico',
        privateAccount: 'Mantener mi cuenta privada',
        notifications: 'Receive notifications',
        updates: 'Recibe actualizaciones sobre todos mis favoritos',
        deleteAccount: 'Borrar cuenta',
        text1: 'Visualizar',
        text2: 'tu',
        text3: 'Marcadores',
      },
      bookmarklet: {
        title: 'Agregar un marcador',
        titleRequired: 'Título requerido',
        addNewTab: 'Agregar nueva pestaña',
        addBookmark: 'Añadir marcador',
        drag: 'Arrastra este botón a la barra de marcadores.',
        install: 'Para instalar el botón "Fave It":',
        installex1: 'Muestre sus marcadores haciendo clic en Ctrl + Shift + B en su teclado',
        installex2: 'Arrastra el botón "Favorito" a la barra de marcadores.',
        installex3: 'Cuando esté navegando por la web, presione el botón "Fave It" para marcar un sitio web en su cuenta.',
      },
      login: {
        title: 'Welcome back to',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        requiredField: 'Campo requerido.',
        minLengthUsername: 'El nombre de usuario debe tener un mínimo de 3 caracteres.',
        minLengthPassword: 'La contraseña debe tener un mínimo de 5 caracteres.',
        forgotUsername: '¿Olvidó su nombre de usuario?',
        forgotPassword: 'Forgot password?',
        loginBtn: 'Acceso',
        noAccount: "¿No tienes una cuenta?",
        getStarted: 'Empezar',
        goToLogin: 'Ir a la página de inicio de sesión',
        confirmedAccount: 'La cuenta ha sido confirmada',
      },
      faves: {
        favesByCountryTitle: 'Favoritos por país',
        hide: 'Esconder',
        show: 'Show',
        topFavesTitle: 'Top Faves',
      },
      userTopTabs: {
        addBtn: 'Agrega tus propios favoritos',
        cellTitle: 'Detalles del sitio',
        validUrl: 'Url no es válido!',
        delete: 'Eliminar',
        logo: 'logo',
        save: 'ahorrar',
        mostPopularSiteTitle: 'Agregar nuevo sitio a la lista de los más populares',
        sitetitle: 'Sitio',
        position: 'Posición',
        close: 'Cerrar',
        tabDetails: 'Detalles de la pestaña',
        private: 'privado',
        public: 'público',
        share: 'Cuota',
        copy: 'copiar link',
      },
      forgotPassword: {
        title: 'Has olvidado tu contraseña',
        forgotBtn: 'HAS OLVIDADO TU CONTRASEÑA',
      },
      resetPassword: {
        title:'Restablecer la contraseña',
        resetBtn: 'RESTABLECER LA CONTRASEÑA',
        passwordMinLength: 'La contraseña debe tener más de 8 caracteres.',
      },
      register: {
        title: 'Crea tu cuenta Wibki',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        requiredField: 'Campo requerido.',
        registerBtn: 'Crear una cuenta',
        haveAccount: '¿Tener una cuenta?',
        signInBtn: 'Registrarse',
        acceptTC: 'Acepto',
        pleaseAcceptTC: 'Acepta los términos y condiciones',
        termsConditions: 'T&C',
        visualBookmarks: 'Marcadores visuales',
        visualBookmarksTxt: 'Agregue y administre todos sus marcadores bajo un mismo techo.',
        discoverMore: 'Discover More',
        discoverMoreTxt: 'Encuentre una variedad de sitios web increíbles sobre cualquier tema.',
        faveIt: 'Fave It',
        faveItTxt: 'Guarde fácilmente sus favoritos usando el botón "Fave It".',
        everywhere: 'En todos lados',
        everywhereTxt: 'Úselo en todas partes. En cualquier computadora, teléfono inteligente o tableta.',
      },
      terms: {
        termsOfUse: 'Terms of use',
        amfInc: 'Wibki Inc.',
        // eslint-disable-next-line max-len
        followingConditions: 'Wibki provides its services to you subject to the following conditions. By visiting wibki.com, you accept these conditions. Please read them carefully. Wibki Inc. is not responsible for the content or the privacy policies of websites to which it may link',
        privacyPolicy: 'Privacy Policy',
        licensing: 'Licensing',
        // eslint-disable-next-line max-len
        licensingTxt: 'The logos that appear on Wibki are of a company, organization, item, product or event, and are protected by copyright and/or trademark. It is believed that the use of low-resolution images of logos to illustrate the Company, organization, item, product or event in question, to use a visual link directly to the Company, organization, item, or event associated with the respective links/logo, qualifies as fair use under Copyright Law. Any featured companies, organizations, items or events on the wibki.com website that wish to be removed from Wibki, may contact us at hi@wibki.com and we shall remove these upon request.',
        terms1: 'We reserve the right to modify or terminate the Wibki service for any reason, without notice at any time.',
        terms2: 'You must be at least 13 years or older to sign up for wibki.com.',
        // eslint-disable-next-line max-len
        terms3: 'Initiating or taking part in any malicious behavior towards Wibki Inc., wibki.com, its Users or any third party via the Company\'s website or under your Wibki account is strictly forbidden. Any malicious and/or obtrusive actions or behavior on part of the User, as judged by Wibki Inc., shall result in the prompt termination of your Wibki account until further notice.',
        // eslint-disable-next-line max-len
        terms4: 'Creating, sharing and/or submitting irrelevant or malicious email messages or \'spam\' to any Wibki members is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms5: 'Any activity that takes place or is initiated under your Wibki User name and/or account, including any content exchanged between and shared with other Users or any other third parties is under your own responsibility.',
        terms6: 'Transmitting any worms, viruses or any malicious code is strictly forbidden.',
        terms7: 'It is your responsibility as a User to safeguard your User name and password.',
        // eslint-disable-next-line max-len
        terms8: 'It is strictly forbidden to use the Wibki service for any illegal and/or unauthorized purpose. International Users agree to comply with all local laws associated with online conduct and appropriate content.',
        // eslint-disable-next-line max-len
        terms9: 'Violating any laws in your jurisdiction (including but not limited to copyright laws) while using the Wibki service is strictly forbidden.',
        // eslint-disable-next-line max-len
        terms10: 'Users are the sole persons responsible for online conduct, the links and sites they choose to add to their account and any derived content and information thereof. The choice of sites added to the personalized Wibki feature is entirely up to the User, and Wibki Inc. and/or Wibki shall not be responsible or held accountable for any inappropriate content as a result.',
        // eslint-disable-next-line max-len
        terms11: 'Making changes to, adapting, hacking or making use of the Wibki service for any purpose or reason other than those described in this document or anywhere else on the website, including any false representation of Wibki and/or association with Wibki is strictly forbidden. Offenders shall be prosecuted accordingly.',
        terms12: 'Wibki reserve the right to refuse service to anyone for any reason at any time, as directed by any applicable laws.',
        terms13: 'Wibki may, but is not obligated to, delete accounts and/or content that we deem inappropriate, malicious, illegal, obtrusive, harmful, abusive or otherwise in violation of any of these Terms of Use.',
        terms14: 'Wibki reserves the right to change or delete User names for any reason as it sees fit, without any obligation to provide the respective Users with any notice.',
        terms15: 'Wibki reserves the right to change or delete User names on behalf of companies, businesses or individuals, for which those User names are a source of legal, registered trademark or intellectual property dispute and/or claim.',
        terms16: 'You understand and agree that by adding a site to your Wibki personalized home page you do not own, neither entirely nor partially, or maintain any copyright of the chosen site\'s trademark.',
        termsViolation: 'Violation of or failure to adhere to any of these agreements shall result in the prompt termination of your Wibki account until further notice. Although Wibki does not allow such conduct and content on its site, you, the User, are aware of, understand and agree that Wibki can not and will not be held responsible for the nature or quality of the content posted by Users on the web site and you, the User, may be exposed to such materials. Using the Wibki service is under your sole responsibility and at your own risk.',
        termsReservedRight: 'Wibki reserves its right to modify any part or all of these Terms of Use at any time and the reason for and onset of such modifications is up to the sole discretion of Wibki.',
        games: 'Games',
        gamesTerms1: 'Wibki holds no responsibility for any of the games featured on Wibki or content thereof. All of the featured games were neither developed nor created by Wibki.',
        gamesTerms2: 'Wibki can not be held reliable for the content provided by and within each game.',
        gamesTerms3: 'Games featured on Wibki may contain violence, suggestive themes, crude humor, blood and gore, simulated gambling and/or infrequent use of strong language. By proceeding to play the game you agree that you have understood the respective game\'s disclaimers, if any, or any other warnings provided by the games\' creators.',
        gamesTerms4: 'Wibki is not responsible for any vulgar, offensive or abusive language, or any other inappropriate expressive language which may offend individuals or groups in general.',
        gamesTerms5: 'All visitors may enter and play multi-player games at their own risk.',
        gamesTerms6: 'Games which are featured on Wibki are copyrighted property of their respective owner/developer. An owner/developer of a game who wishes to be removed from Wibki may contact us at hi@wibki.com and we shall remove it upon request.',
        disclaimerWarrantyLiability: 'Disclaimer of Warranties and Limitation of Liability',
        discWarrLiTxt1: 'THIS SITE IS PROVIDED BY Wibki INC. ON AN "AS IS" AND "AS AVAILABLE" BASIS. wibki.com MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPLICITLY, IMPLICITLY OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS OR PRODUCTS INCLUDED ON THIS SITE. YOU HEREBY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR OWN RISK.',
        discWarrLiTxt2: 'TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, wibki.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. wibki.com DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM wibki.com ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. wibki.com WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.',
        discWarrLiTxt3: 'CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.',
        other: 'Other',
        otherTxt: 'Wibki and you are independent entities, and nothing in the Wibki Terms, or via use of Wibki , will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between Wibki and you. These Wibki Terms supersede any previous agreement related to your use of Wibki and represent the entire agreement between Wibki and you with regard to your use of Wibki.',
        yourFeedback: 'Your Feedback',
        yourInput: 'Wibki welcomes your continuous input regarding our Terms of Use & Privacy Policy or our provided services. You may send us your comments and responses to hi@wibki.com'
      },
      privacy: {
        statement: 'Privacy statement of Wibki Inc.',
        info: 'Information regarding the Wibki organization and web site:',
        privacyInfo: 'Wibki Inc. recognizes that your privacy is very important. Please read the information below to learn more about our privacy policy guidelines. Since this is a website in progress with continual additions and expansions, and because Internet technologies are constantly evolving, these guidelines are subject to change. It is up to Wibki\' discretion to make any changes as it sees fit, and any such changes shall be posted on this page.',
        advised: 'You are advised to consult this Privacy Policy regularly for any changes.',
        notResponsible: 'Wibki Inc. is not responsible for the content or the privacy policies of websites to which the site may link.',
        principal: 'Our principal activity entails a visual portal and bookmark manager.',
        cover: 'Our privacy policy covers Wibki Inc. and its website:',
        organisationName: 'Organisation Name',
        city: 'City',
        country: 'Country',
        phone: 'Phone number',
        webSites: 'Web Site(s)',
        atAMF: 'At Wibki, we recognize that your privacy and that of your personal information is important. Below we provide information on what types of personal information we receive and maintain in our company\'s records when you use/visit Wibki, and how we safeguard that information.',
        collect: 'Wibki collects and uses personally identifiable information for the following reasons and purposes only: providing users with the services made available through the wibki.com including, but not limited to the service, to communicate with users, and to manage users\' registered accounts, if and when applicable.',
        logFiles: 'Log Files',
        logFilesTxt1: 'As with most other websites, our servers automatically record information that your browser sends whenever you visit a website ("Log Data" ). The information in the log files may include your IP address (Internet Protocol) , your ISP (Internet Service Provider, such as AOL or Shaw Cable), the browser through which you visit our site (such as Internet Explorer or Firefox), the time at which you visited our site, and which pages you visited within the entire Wibki site.',
        logFilesTxt2: 'We use Log Data to monitor the use of the Site and of our Service. We do not associate your IP address with any other personally identifiable information to identify you personally, except in case of violation of the Terms of Use.',
        cookiesBeacons: 'Cookies and Web Beacons',
        cbTxt1: 'Wibki uses cookies to store information such as your personal preferences when you visit our site. If you are not familiar with what cookies are, here is a brief explanation: A cookie is a small data file many websites (wibki.com included) carry to your computer\'s hard disk. Wibki does not use cookies to collect users\' personally identifiable information. We use cookies as an improvement tool for the Company, the wibki.com website and our offered services. More specifically, we use cookies as data information regarding users\' site preferences and navigation behavior, and website traffic and referred visitor statistics (navigation between wibki.com and other websites).',
        cbTxt2: 'A session cookie makes certain site features and services possible, yet is only a session long, meaning once you log out or turn your computer off, the cookie ceases its activity and is removed from your computer. A persistent cookie stays in your computer despite disconnecting from your browser, and is used by the browser in re-visits to the web site. You can remove persistent cookies by consulting your browser support/help service.',
        cbTxt3: 'Most Internet browsers automatically accept cookies. You can chose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or manage your preferences in programs such as Norton Internet Security. However, this can affect how you are able to interact with our site as well as other websites. One of the consequences might be users\' inability to log in to services or programs, such as logging into forums or accounts.',
        ads: 'Advertisement',
        adsTxt: 'Wibki uses third party advertisements on Wibki to support our web site. Some of these advertisers may use web technologies such as cookies and web beacons on our site. These technological tools send participating advertisers (such as Google through the Google AdSense program) information such as your IP address, your ISP , the browser you used to visit our site, and in some cases, information regarding your installed Flash software. This is generally used for geo-targeting purposes (showing New York real estate ads to someone in New York, for example) or for showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).',
        infoCollect: 'Information Collected by Wibki',
        infoCollectTxt1: 'Although using wibki.com is free for everyone, users need to register in order to take advantage of certain services and features on wibki.com, such as the MiniFaves feature. As a registered user, you will be asked to provide us with certain personally identifiable information. Such information may include your computer\'s IP address, user name, password, email address, geographic location and local time, phone number, and any other details you choose to submit to us or include in your personalized MiniFaves page.',
        infoCollectTxt2: 'By adding your own choice of sites to your personalized Wibki homepage, you allow Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        useOfContactInfo: 'Use of Contact Information',
        useOfContactInfoTxt: 'We may use your contact information to send you updates and promotions of current and future Wibki features and offered services. Should you decide to discontinue the recipt of such information, you are welcome to unsubscibe via our updates and/or promotion messages sent to your email.',
        serviceProviders: 'Service Providers',
        serviceProvidersTxt: 'Wibki has agreements with certain external service providers and other reliable and safe third parties for the Company and website\'s managment and maintenance. These include but are not limited to hosting and maintenance providers, database storage and management services, and marketing projects. As an wibki.com\'s user, your personally identifiable information may be shared with these third parties on a limited basis, meaning only for the purpose of performing and providing wibki.com\'s services and features. In addition, sharing that personally identifiable information shall occur, if at all, only after those third parties have made an explicit obligation to safeguard your privacy and ensure the security of your data.',
        confidentiality: 'Confidentiality',
        confidentialityTxt: 'Feedback and other comments and statements that you chose to submit to Wibki by any means - whether by e-mail to Wibki Customer Service, telephone, letter, or any other communication method - shall be considered nonconfidential. Wibki is free to use such information on an unrestricted basis.',
        useInfo: 'The ways in which we may use your information',
        useInfoTxt1: 'We use users\' collected information to improve our marketing and promotional efforts; to analyze site usage with statistics and data; to improve our content and product offerings, and; to customize the Wibki web site\'s content, layout and services, for the benefit of our users. We may use your collected information to service your account with us, including but not limited to investigating problems, resolving disputes and enforcing agreements with us. We do not sell, rent, trade or exchange any personally-identifying information of our users. We may share certain aggregate information based on analysis of collected information with our partners, customers, advertisers or potential users. We may use your collected information to execute marketing campaigns, promotions or advertising messages on behalf of third parties. Information submitted through the \'Tell a Friend\' feature is collected and used in the mail which is sent to the referred person or \'friend\' who was listed in the submission.',
        useInfoTxt2: 'By posting content, you are granting permission to Wibki and others to access and use it in connection with Wibki and otherwise in connection with its affiliates & businesses. You can mark content as private to restrict access and use to those users to whom you explicitly grant access. Your use of the content does not affect Wibki\' right to access and use it in connection with Wibki or otherwise in connection with its affiliates & businesses.',
        linksToOther: 'Links to other sites',
        linksToOtherTxt: 'Although Wibki provides numerous links to websites, this action is by no means any endorsement, advertisement for profit, authorization or representation of our affiliation with those third parties or featured sites, excluding sites and parties with which Wibki has a contracted agreement. Wibki has no control over any of the sites we feature anywhere on the website. Featured sites on Wibki may use cookies of their own or other files on your computer, collect data or solicit personally identifiable information from you. Other sites comply with different rules regarding the use or disclosure of the personally identifiable information submitted to them by users. Please consult the privacy policies and/or statements of the other websites you visit.',
        policyChildren: 'Wibki\' Policy Regarding Children',
        policyChildrenTxt: 'Only users at least 13 years old or older may use wibki.com. Children under 13 are welcome to use the website under the supervision of their parents, legal gurdian or other authorized adult. However, Children may not sign or provide any perosnally identifiable information to wibki.com. If you are a parent or legal guardian who has learned that the child under your care is or has provided us with personally identifiable information without your knowledge and/or concent, contact us at hi@wibki.com. wibki.com does not knowingly collect personally identifiable information from children under 13. Should we learn of such a case, his or her information shall be removed immediately from our records.',
      },
      aboutUs: {
        aboutUsPageTitle: 'About Us',
        homepageTitle: 'Wibki is the Ultimate Homepage',
        homepageContent: 'We like to keep it simple. Why search? is the essence of Wibki\' philosophy. We believe the Internet should be an inspiring, easy and free experience for everyone. We, too, think that there must be an appealing and simple alternative to traditional Internet navigation. That is why we have taken up the task of simplification so that end-users could find what they\'re looking for quickly, whether they are interested in Entertainment, Games, Kids, Shopping, Travel or anything else. With Wibki, users can smoothly cruise on the vast Internet highway without wasting valuable time or having to sieve through irrelevant search results.',
        homepageContent2: 'Our visual platform not only directs users to their sought-after information fast but also introduces them to new and exciting sites, both of the same and different categories. We achieve this through a strict selection process and a back-and-forth dialogue among the Wibki team members. This way, Wibki acts as a pioneering force of Internet browsing, searching and learning, thereby offering nothing but the absolute best of what the web has to offer. Today, when much of the searches we perform produce considerable numbers of spam, fraud and aggregation sites, we feel someone needs to step up and sift through the Internet so that Internet users\' experience is a positive, to the point and no-nonsense one. Wibki has a calling and we\'re dead set on doing it right. What a better way to do so than by the use of the human brain.',
        historyTitle: 'History and Approach',
        historyContent: 'Back in 2006, Shachar Pessis grew frustrated by the cumbersome and convoluted nature of the Internet. To find something on the web, he had to type in URLs, use his ever-increasing list of bookmarks, enter key words in search engine boxes, or be acquainted with an Internet savvy friend who could recommend relevant sites to him. Sure enough, Shachar learned he was not alone: 72.3 percent of adult Americans experience \'search engine fatigue\' either always, usually or sometimes when searching for a topic on the web ("The State of Search" - compiled by Autobytel Inc. with Kelton Research, October 2007). Discovering new sites was even more challenging and time-consuming, and rarely did Shachar accomplish this in a fast and easy manner.',
        historyContent2: 'Shachar shared his discontentment with Roy Pessis, his brother. Together they came to the conclusion that a better alternative must exist, one that applies to and is shareable by everyone, everywhere. The two brothers then held brainstorming sessions and came up with the Wibki concept. They envisioned a virtual directory that includes a list of top and most visited sites in major daily-used subject categories, a list they knew would introduce new and interesting things to others as it grew larger. Shachar and Roy aimed for a simple way by which users can discover new fields of interest without having to think about these beforehand or surfing the Internet with the initial intent of learning new things.',
        historyContent3: 'The Wibki\' Internet team scans the Internet for the best and latest sites on the web. Our experts are constantly hard at work in examining the Internet\'s various aspects, categories and sites. By doing so, we eliminate all the crudeness of the Internet and offer users a purified, virtually distilled version of the web. Becoming a Faves expert is no easy task; new \'fave selectors\' who come aboard Wibki go through a personal and ongoing training program in order to perform high quality filtration of the thousands of sites they come across every week. Indeed, we are stern believers in human editing skills versus those of a machine or software.',
        myFavesTitle: 'My Faves',
        myFavesContent: 'The My Faves feature is one of Wibki\' most exciting components (',
        myFavesContentLink: 'click here to join',
        myFavesContent2: '). Although we do our best in making Wibki your one-stop-shop for all your Internet needs, we do recognize the fact that each user is into different fields of interests and activities. Therefore, we\'re giving users their own creation powers for free. Once you\'ve signed up and have logged in, you\'ll have the option of adding your choice of new sites.',
        weeklyFaves: 'Weekly Faves',
        weeklyFavesContent: 'Updated every Monday, the Weekly Faves are our hand-picked sites for that week. These are the sites we were most impressed by and the ones we wish to pay forward to our users. Think of it as the \'crème de la crème\' of the Internet on a weekly basis. Accordingly, the Weekly Faves offer users a little bit of everything each week with the added value of introducing new sites and fields of interest to users.',
        missedSomethingTitle: 'We Missed Something?',
        missedSomethingContent: 'Check out our ',
        missedSomethingLink: 'Frequently Asked Questions',
        missedSomethingLink2: 'write us',
        missedSomethingContent2: ' for more info. Or ',
        missedSomethingContent3: ' with whatever is on your mind.',
        joinUsTitle: 'Join Us',
      },
      press: {
        pressPageTitle: 'Press Room'
      },
      bookmark: {
        faveBookmarkPageTitle: 'El marcador',
        saveBookmark: 'Guarda fácilmente tus favoritos',
      },
      contact: {
        title: 'Contacta con nosotros',
        subtitle1: 'Tu opinión significa mucho para nosotros. Si tiene un comentario, sugerencia, queja o recomendación de un sitio / categoría, envíenos un correo electrónico a',
        subtitle2: 'o complete el formulario a continuación.',
        checkboxTitle: '¿Cómo te enteraste de nosotros?',
        checkbox: {
          search: 'Search',
          referringSite: 'Reffering Site',
          tv: 'Television',
          radio: 'Radio',
          newspaper: 'Newspaper',
          world: 'World Of Mouth'
        },
      },
      footer: {
        titleSection1: 'Todos mis favoritos',
        aboutUs: 'Sobre nosotros',
        join: 'Entrar',
        tellAFriend: 'Dile a un amigo',
        press: 'prensa',
        blog: 'Blog',
        adminArea: "Área de administración",
        support: 'Apoyo',
        faq: 'FAQ',
        wantMore: '¿Quieren más?',
        contactUs: 'Contacta con nosotros',
        oldWebsite: 'Old Website',
        downloadApp: "Download the App",
        privacy: 'Privacy Policy',
        terms: 'Condiciones de uso',
        rights: 'Reservados todos los derechos.',
      },
      navbar: {
        topFaves: 'Top Favoritos',
        shop: 'Tienda',
        shopping: 'Compras',
        apparel: 'Vestir',
        homeElectronics: 'Hogar y electrónica',
        discover: 'Descubrir',
        weeklyFaves: 'Favoritos Semanales',
        weeklyGames: 'Juegos Semanales',
        favesByCountry: 'Favoritos por país',
        blog: 'Blog',
        settings: 'Ajustes',
        myFaves: 'Mis favoritos',
        interests: 'Agregar intereses',
        faveButton: 'Botón Fave It',
        faq: 'FAQ',
        sideBarHome: 'Navegación',
        home: 'Hogar',
        profile: 'Perfil',
        adminMenu: 'Menú de administración',
      },
    },
  },
});

export default i18n;
