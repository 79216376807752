<template>
  <div id="rec-sidebar">
    <md-toolbar class="md-accent">
      <span v-if="!user"> {{ $t('navbar.sideBarHome') }}</span>
      <span v-if="user">{{ user.username }}</span>

      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="toggleSidebar">
          <i class="fas fa-times fa-2x"></i>
        </md-button>
      </div>
    </md-toolbar>

    <md-list :md-expand-single="expandSingle" class="md-dense">
      <router-link tag="md-list-item" :to="{ name: 'home'}" :class="'menu-item'" exact>
        <md-icon><i class="fas fa-home"></i></md-icon>
        <span class="md-list-item-text"> {{ $t('navbar.home') }}</span>
      </router-link>

      <router-link v-if="isAuthenticated" tag="md-list-item" :to="{ name: 'userDashboard'}" :class="'menu-item'">
        <md-icon><i class="fas fa-user"></i></md-icon>
        <span class="md-list-item-text"> {{ $t('navbar.profile') }}</span>
      </router-link>

      <md-list-item v-if="isAuthenticated" @click="logout" :class="'menu-item'">
        <md-icon><i class="fas fa-sign-out-alt"></i></md-icon>
        <span class="md-list-item-text"> {{ $t('logout') }}</span>
      </md-list-item>

      <md-list-item v-if="!isAuthenticated" :to="{name: 'userLogin'}" :class="'menu-item'">
        <md-icon><i class="fas fa-sign-in-alt"></i></md-icon>
        <span class="md-list-item-text"> {{ $t('loginBtn') }}</span>
      </md-list-item>

<!--      ===============================================-->
<!--      ===============================================-->
<!--      ===============================================-->
<!--      ===============================================-->
<!--      ===============================================-->
      <md-divider v-if="isAdmin"></md-divider>
      <md-subheader v-if="isAdmin"> {{ $t('navbar.adminMenu') }}</md-subheader>

      <router-link v-if="isAdmin" tag="md-list-item" :to="{ name: 'adminDashboard'}" :class="'menu-item'">
        <md-icon><i class="fas fa-home"></i></md-icon>
        <span class="md-list-item-text">Dashboard</span>
      </router-link>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandUsers">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">Users</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminUsersListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Admins</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminUsersListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Users</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminUsersListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">WibkiUsers</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminUsersListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Line Users</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandUsersTabs">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">UserTabs</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminUserTabsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminUserTabsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandTabCells">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">TabCells</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminTabCellsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminTabCellsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandSites">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">Sites</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminSitesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminSitesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandFavPages">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">FavPages</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminFavPagesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminFavPagesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandFavLines">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">FavLines</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminFavLinesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminFavLinesListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandFavCells">
        <md-icon><i class="fas fa-users"></i></md-icon>
        <span class="md-list-item-text">FavCells</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminFavCellsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">List</span>
          </router-link>

          <router-link tag="md-list-item" :to="{ name: 'adminFavCellsListing'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Create</span>
          </router-link>
        </md-list>
      </md-list-item>

      <md-list-item v-if="isAdmin" md-expand :md-expanded.sync="expandSettings">
        <md-icon><i class="fas fa-cogs"></i></md-icon>
        <span class="md-list-item-text">Settings</span>

        <md-list slot="md-expand">
          <router-link tag="md-list-item" :to="{ name: 'adminDashboard'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">General</span>
          </router-link>
          <router-link tag="md-list-item" :to="{ name: 'adminDashboard'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Layout</span>
          </router-link>
          <router-link tag="md-list-item" :to="{ name: 'adminDashboard'}">
            <md-icon><i class="fas fa-user-tie"></i></md-icon>
            <span class="md-list-item-text">Email</span>
          </router-link>
        </md-list>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import sidebarMixin from '@/mixins/sidebarMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';

export default {
  name: 'RecSidebar',
  mixins: [
    sidebarMixin,
    loggedInUserMixin,
  ],
  data() {
    return {
      expandSingle: true,
      expandUsers: false,
      expandUsersTabs: false,
      expandTabCells: false,
      expandSites: false,
      expandSettings: false,
      expandFavPages: false,
      expandFavLines: false,
      expandFavCells: false,
    };
  },
};
</script>
