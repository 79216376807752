<template>
  <div class="md-layout md-alignment-center-center search-container">
    <div class="md-layout-item md-size-70 md-alignment-center-center searchbar-item md-medium-size-60 md-small-size-50">
      <form :action="searchAction" method="get" target="_blank" id="search-form" @submit.prevent="search">
        <md-autocomplete :class="invalidQuery"
          v-model="googleSearchSelected"
          :md-options="searchItems"
          md-layout="box"
          md-min-length="1"
          md-dense data-gname="storesearch"
          title="Enter your query and then select the search engine">
          <label>Search</label>
        </md-autocomplete>
      </form>
    </div>
    <div class="md-alignment searchbar-item" style="z-index: 0;">
      <div class="md-layout-item md-size-100">
        <md-button title="Google Search" class="md-icon-button md-raised md-primary google-btn" @click="search('google')">
          <i class="fab fa-google"></i>
        </md-button>
        <md-button title="Amazon Search" class="md-icon-button md-raised md-accent amazon-btn" @click="search('amazon')">
          <i class="fab fa-amazon"></i>
        </md-button>
        <md-button title="Youtube Search" class="md-icon-button md-raised md-primary youtube-btn" @click="search('youtube')">
          <i class="fab fa-youtube"></i>
        </md-button>
      </div>
    </div>

  </div>
</template>

<script>
import renderedUserTabs from '@/mixins/renderedUserTabs';
import tabActionsMixin from '@/mixins/tabActionsMixin';
import {
  SET_SELECTED_TAB_NAME_BY_SEARCH,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'SearchBar',
  mixins: [tabActionsMixin, renderedUserTabs],
  props: {
    currentRouteName: String,
  },
  data() {
    return {
      googleSearchSelected: '',
      searchAction: null,
      publicPath: process.env.BASE_URL,
      html: '',
      publicUsername: null,
      adminMode: false,
    };
  },
  created() {
    const queryParamter = window.location.search.toString().substring(1, 2);
    let query = '';
    if (queryParamter === 'q') {
      query = window.location.search.toString().substring(3);
    }
    this.googleSearchSelected = query;
    this.setUserTabs();
  },
  methods: {
    search(website) {
      if (this.googleSearchSelected) {
        const query = this.googleSearchSelected;
        this.googleSearchSelected = '';
        switch (website) {
          case 'google':
            window.open(this.$router.resolve({ path: `/google/search?q=${query}` }).href, '_blank');
            break;
          case 'amazon':
            this.searchAction = `https://www.amazon.com/s?k=${query}`;
            window.open(this.searchAction, '_blank');
            break;
          case 'youtube':
            this.searchAction = `https://www.youtube.com/results?search_query=${query}`;
            window.open(this.searchAction, '_blank');
            break;
          default:
            window.open(this.$router.resolve({ path: `/google/search?q=${query}` }).href, '_blank');
        }
      } else {
        this.googleSearchSelected = null; // to show the border
        setTimeout(() => {
          this.googleSearchSelected = '';
        }, 500);
      }
    },
  },
  computed: {
    invalidQuery() {
      return this.googleSearchSelected != null ? '' : 'invalid-query';
    },
    searchItems() {
      return this.originalTabs ? this.originalTabs.map(item => item.name) : [];
    },
    myFavesUser() {
      return this.$store.getters.userData;
    },
  },
  watch: {
    myFavesUser: {
      handler(val) {
        if (val) {
          this.publicUsername = val;
          this.setUserTabs();
        }
      },
    },
    googleSearchSelected: {
      handler(val) {
        this.$store.dispatch(SET_SELECTED_TAB_NAME_BY_SEARCH, val);
      },
    },
  },
};
</script>

<style lang="scss">
.md-theme-default a:not(.md-button) , .md-theme-default a:not(.md-button):hover{
  color: unset;
}

@import '@/assets/scss/layout/search-bar.scss';
</style>
