/* eslint-disable import/no-cycle,no-shadow */
import LocalStorageService from '@/services/localStorage.service';
import TabCellsService from '@/services/tabCells.service';
import FilesService from '@/services/files.service';
import AuthUserTabsService from '@/services/authUserTabs.service';
import { FETCH_ADMIN_USER } from '@/store/modules/admin/resources/users/usersActions.type';
import {
  FINISHED_LOADING,
  IS_LOADING,
  SAVE_CELL_LOADING,
  FINISH_SAVE_CELL_LOADING,
  CHANGE_CELL_LOADING,
  FINISH_CHANGE_CELL_LOADING,
} from '@/store/modules/layout/layoutActions.type';
import {
  CREATE_USER_TAB_SUCCESS,
  UPDATE_TAB_CELL_SUCCESS,
  DELETE_USER_TAB_SUCCESS,
  DELETE_TAB_CELL_SUCCESS,
  SET_SELECTED_TAB_INDEX_M,
  SET_SELECTED_TAB_M,
  RESET_SELECTED_TAB_INDEX_M,
  SET_HIDE_LINES_M,
  RESET_TAB_CELL_EDITABLE_ID_M,
  SET_TAB_CELL_EDITABLE_ID_M,
  SET_SHARE_TAB_MODAL_VALUE_M,
  SET_TAB_MODAL_VALUE_M,
  SET_EDITABLE_TAB_MODAL_VALUE_M,
  GET_AUTH_USER_TABS_SUCCESS,
  SET_SELECTED_TAB_NAME_BY_SEARCH_M,
} from './authUserTabsMutations.type';
import {
  CREATE_USER_TAB,
  UPDATE_USER_TAB,
  DELETE_USER_TAB,
  CREATE_TAB_CELL,
  UPDATE_TAB_CELL,
  DELETE_TAB_CELL,
  UPLOAD_IMAGE_TAB_CELL,
  SET_SELECTED_TABA,
  SET_SELECTED_TAB_INDEX,
  RESET_SELECTED_TAB_INDEX,
  SET_HIDE_LINES,
  SET_TAB_CELL_EDITABLE_ID,
  RESET_TAB_CELL_EDITABLE_ID,
  SET_SHARE_TAB_MODAL_VALUE,
  SET_TAB_MODAL_VALUE,
  SET_EDITABLE_TAB_MODAL_VALUE,
  GET_AUTH_USER_TABS,
  UPDATE_TAB_CELL_POSITION,
  SET_SELECTED_TAB_NAME_BY_SEARCH,
} from './authUserTabsActions.type';
import { SET_USER } from '../authActions.type';

const state = {
  userTabs: LocalStorageService.getUserTabs(),
  selectedTabIndex: LocalStorageService.getSelectedTabIndex(),
  selectedTabNameBySearch: '',
  selectedTab: null, // set the selcted tab including the tabs order
  selectedTabCellIdToEdit: null,
  isModalVisibleTab: false,
  isTabModalVisible: false,
  isShareTabModalVisible: false,
  isEditableTabModalVisible: false,
  hideLines: LocalStorageService.getHideLines(),
};

const getters = {
  userTabs(state) {
    return state.userTabs;
  },
  selectedTabIndex(state) {
    if (state.userTabs) {
      if (window.location.pathname === '/') { // homepage
        // discuss that to avoid a blank cells section in case you were on myfaves for another user with more tabs
        return state.selectedTabIndex < state.userTabs.length ? state.selectedTabIndex : 0;
      }
    }
    return state.selectedTabIndex;
  },
  isShareTabModalVisible(state) {
    return state.isShareTabModalVisible;
  },
  isModalVisibleTab(state) {
    return state.isModalVisibleTab;
  },
  isEditableModalVisibleTab(state) {
    return state.isEditableTabModalVisible;
  },
  hideLines(state) {
    return state.hideLines;
  },
  selectedTab(state) {
    return state.selectedTab ?? (state.userTabs ? state.userTabs[state.selectedTabIndex] : {});
  },
  selectedTabCellIdToEdit(state) {
    return state.selectedTabCellIdToEdit;
  },
  selectedTabCellObjToEdit(state) {
    if (state.selectedTabCellIdToEdit) {
      return state.userTabs[state.selectedTabIndex] && state.userTabs[state.selectedTabIndex].tabCells.find(item => item.id === state.selectedTabCellIdToEdit)
        ? state.userTabs[state.selectedTabIndex].tabCells.find(item => item.id === state.selectedTabCellIdToEdit)
        : state.selectedTabCellIdToEdit;
    }
    return state.userTabs && state.userTabs[state.selectedTabIndex] ? state.userTabs[state.selectedTabIndex].id : 0;
  },
  selectedTabNameBySearch(state) {
    return state.selectedTabNameBySearch;
  },
};

const actions = {
  [CREATE_USER_TAB](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING).then(() => {
        const { adminMode } = payload;
        AuthUserTabsService.createUserTab(payload)
          .then((response) => {
            if (adminMode) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.commit(DELETE_USER_TAB_SUCCESS, payload);
            context.dispatch(GET_AUTH_USER_TABS).then();
            setTimeout(() => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            }, 500);
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [UPDATE_USER_TAB](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING).then(() => {
        const { adminMode } = payload;
        AuthUserTabsService.updateUserTab(payload)
          .then((response) => {
            if (adminMode) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(GET_AUTH_USER_TABS).then();
            setTimeout(() => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            }, 500);
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [DELETE_USER_TAB](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING).then(() => {
        const { adminMode } = payload;
        AuthUserTabsService.deleteUserTab(payload.editTabId)
          .then((response) => {
            if (adminMode) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.commit(DELETE_USER_TAB_SUCCESS, payload);
            context.dispatch(GET_AUTH_USER_TABS).then();
            setTimeout(() => {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            }, 500);
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [CREATE_TAB_CELL](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(SAVE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        TabCellsService.create(payload)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(GET_AUTH_USER_TABS).then();
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              resolve(response.data);
            });
            context.dispatch(FINISHED_LOADING).then();
          })
          .catch((error) => {
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [UPDATE_TAB_CELL](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(SAVE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        TabCellsService.update(payload)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(GET_AUTH_USER_TABS).then();
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              resolve(response.data);
            });
            context.dispatch(FINISHED_LOADING).then();
          })
          .catch((error) => {
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [UPDATE_TAB_CELL_POSITION](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(CHANGE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        TabCellsService.updatePosition(payload)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            context.dispatch(GET_AUTH_USER_TABS).then(() => {
              context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
                resolve(response.data);
              });
              context.dispatch(FINISHED_LOADING).then();
            });
          })
          .catch((error) => {
            context.dispatch(FINISH_CHANGE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [DELETE_TAB_CELL](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(SAVE_CELL_LOADING).then(() => {
        const { adminMode } = payload;
        TabCellsService.destroy(payload.id)
          .then((response) => {
            if (adminMode && context.getters.userData) {
              context.dispatch(FETCH_ADMIN_USER, context.getters.userData.id).then();
            }
            // eslint-disable-next-line no-param-reassign
            payload.publicPath = response.data.data.public_path;
            // context.commit(DELETE_TAB_CELL_SUCCESS, payload);
            context.dispatch(GET_AUTH_USER_TABS).then();
            setTimeout(() => {
              context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
                resolve(response.data);
                context.dispatch(FINISHED_LOADING).then();
              });
            }, 500);
          })
          .catch((error) => {
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [UPLOAD_IMAGE_TAB_CELL](context, payload) {
    return new Promise((resolve, reject) => {
      context.dispatch(SAVE_CELL_LOADING).then(() => {
        FilesService.create(payload)
          .then((response) => {
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              resolve(response.data);
            });
          })
          .catch((error) => {
            context.dispatch(FINISH_SAVE_CELL_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
  [SET_SELECTED_TABA](context, payload) {
    context.commit(SET_SELECTED_TAB_M, payload);
  },
  [SET_SELECTED_TAB_INDEX](context, payload) {
    context.commit(SET_SELECTED_TAB_INDEX_M, payload);
  },
  [SET_SELECTED_TAB_NAME_BY_SEARCH](context, payload) {
    context.commit(SET_SELECTED_TAB_NAME_BY_SEARCH_M, payload);
  },
  [RESET_SELECTED_TAB_INDEX](context) {
    context.commit(RESET_SELECTED_TAB_INDEX_M);
  },
  [SET_HIDE_LINES](context, payload) {
    context.commit(SET_HIDE_LINES_M, payload);
  },
  [SET_TAB_CELL_EDITABLE_ID](context, payload) {
    context.commit(SET_TAB_CELL_EDITABLE_ID_M, payload);
  },
  [SET_SHARE_TAB_MODAL_VALUE](context, payload) {
    context.commit(SET_SHARE_TAB_MODAL_VALUE_M, payload);
  },
  [SET_TAB_MODAL_VALUE](context, payload) {
    context.commit(SET_TAB_MODAL_VALUE_M, payload);
  },
  [SET_EDITABLE_TAB_MODAL_VALUE](context, payload) {
    context.commit(SET_EDITABLE_TAB_MODAL_VALUE_M, payload);
  },
  [RESET_TAB_CELL_EDITABLE_ID](context) {
    context.commit(RESET_TAB_CELL_EDITABLE_ID_M);
  },
  [GET_AUTH_USER_TABS](context) {
    return new Promise((resolve, reject) => {
      context.dispatch(IS_LOADING).then(() => {
        AuthUserTabsService.getUserTabs()
          .then((response) => {
            context.dispatch(SET_USER, response.data.data);
            context.commit(GET_AUTH_USER_TABS_SUCCESS, response.data.data);
            if (window.location.pathname === '/') {
              context.dispatch(FINISHED_LOADING).then(() => {
                resolve(response.data);
              });
            } else {
              // keep loader here to prevent displaying the logged in users' tabs
              resolve(response.data);
            }
          })
          .catch((error) => {
            context.dispatch(FINISHED_LOADING).then(() => {
              reject(error);
            });
          });
      });
    });
  },
};

const mutations = {
  [CREATE_USER_TAB_SUCCESS](state, data) {
    state.userTabs.push(data.userTab);
    LocalStorageService.storeUserTabs(state.userTabs);
  },
  [UPDATE_TAB_CELL_SUCCESS](state, data) {
    const currentTabCell = data;
    const currentTab = state.userTabs.find(item => item.id === currentTabCell.tab_id);
    const currentCell = currentTab.tabCells.find(item => item.id === currentTabCell.id);
    currentTab.public_path = currentTabCell.public_path;
    if (data.drag) { // Check if the update is made from drag and drop
      currentCell.position = currentTabCell.position;
      LocalStorageService.storeUserTabs(state.userTabs);
    } else {
      currentCell.link = currentTabCell.link;
      currentCell.alt = currentTabCell.alt;
      LocalStorageService.storeUserTabs(state.userTabs);
    }
  },
  [DELETE_USER_TAB_SUCCESS](state, data) {
    const currentTab = state.userTabs.find(item => item.id === data.editTabId);
    state.userTabs.splice(state.userTabs.indexOf(currentTab), 1);
    LocalStorageService.storeUserTabs(state.userTabs);
    if (state.selectedTabIndex >= state.userTabs.length) {
      LocalStorageService.storeSelectedTabIndex(0);
      state.selectedTabIndex = 0;
    }
  },
  [DELETE_TAB_CELL_SUCCESS](state, data) {
    const currentTab = state.userTabs.find(item => item.id === data.tabId);
    const tabIndex = state.userTabs.findIndex(item => item.id === data.tabId);
    const cellIndex = currentTab.tabCells.findIndex(item => item.id === data.cellId);
    state.userTabs[tabIndex].tabCells.splice(cellIndex, 1); // Delete tab cell
    currentTab.public_path = data.publicPath; // Regenerate the tabsprite
    LocalStorageService.storeUserTabs(state.userTabs);
  },
  [SET_SELECTED_TAB_INDEX_M](state, data) {
    state.selectedTabIndex = data.selectedTabIndex;
    LocalStorageService.storeSelectedTabIndex(data.selectedTabIndex);
  },
  [SET_SELECTED_TAB_NAME_BY_SEARCH_M](state, name) {
    state.selectedTabNameBySearch = name;
  },
  [SET_SELECTED_TAB_M](state, data) {
    state.selectedTab = data;
  },
  [RESET_SELECTED_TAB_INDEX_M](state) {
    state.selectedTabIndex = null;
  },
  [SET_HIDE_LINES_M](state, hide) {
    state.hideLines = hide;
    LocalStorageService.storeHideLines(hide);
  },
  [SET_TAB_CELL_EDITABLE_ID_M](state, data) {
    state.selectedTabCellIdToEdit = data;
  },
  [RESET_TAB_CELL_EDITABLE_ID_M](state) {
    state.selectedTabCellIdToEdit = null;
  },
  [SET_SHARE_TAB_MODAL_VALUE_M](state, value) {
    state.isShareTabModalVisible = value;
  },
  [SET_TAB_MODAL_VALUE_M](state, value) {
    state.isModalVisibleTab = value;
  },
  [SET_EDITABLE_TAB_MODAL_VALUE_M](state, value) {
    state.isEditableTabModalVisible = value;
  },
  [GET_AUTH_USER_TABS_SUCCESS](state, data) {
    state.userTabs = data.userTabs;
    LocalStorageService.storeUserTabs(state.userTabs);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
