<template>
        <div class="cookie-consent-banner">
        <div class="cookie-consent-banner__inner">
            <div class="cookie-consent-banner__header">
              We use
              <router-link class="cookie-link" tag="a" :to="{ name: 'privacy'}">
                cookies
                </router-link>
            </div>
            <div class="cookie-consent-banner__actions">
              <div @click="acceptCookies" class="cookie-consent-banner__cta">
              OK
              </div>
            </div>
        </div>
      </div>
</template>


<script>
import { ACCEPT_COOKIES } from '@/store/modules/user/userActions.type';

export default {
  name: 'RecCookie',
  methods: {
    acceptCookies() {
      this.$store.dispatch(ACCEPT_COOKIES);
    },
  },
};

</script>

<style scoped>
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 20%;
  background: linear-gradient(to bottom right, #a5a5a5, #C44B57);
  border-radius: 10px;
  opacity: 0.8;
  margin-bottom: 10px;
  padding: 5px;
}

.cookie-consent-banner__inner {
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cookie-consent-banner__header {
  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 7px;
  background-color: #a5a5a5;
  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
  width: 100%;
}

.cookie-consent-banner__cta:hover {
  background-color: #C44B57;
}
.cookie-consent-banner__actions {
  width: 30%;
}

.cookie-link {
  color: inherit !important;
  cursor: pointer;
  text-decoration: underline;
}

.cookie-consent-banner {
  width: 70%;
}

@media only screen and (min-width: 400px) {
    .cookie-consent-banner {
        width: 50%;
    }
}

@media only screen and (min-width: 720px) {
    .cookie-consent-banner {
        width: 35%;
    }
}

@media only screen and (min-width: 1000px) {
    .cookie-consent-banner {
        width: 25%;
    }
}

@media only screen and (min-width: 1200px) {
    .cookie-consent-banner {
        width: 20%;
    }
}
</style>
