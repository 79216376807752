/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Role from '@/enums/userRoles';
import store from '@/store';

// import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/google/search',
    name: 'googleSearch',
    component: () => import(/* webpackChunkName: "FavesByCountryName." */ '../views/GoogleSearchPage.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/terms',
    name: 'termsConditions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TermConditions" */ '../views/Terms.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "Home" */ '../views/AboutUs.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/press',
    name: 'press',
    component: () => import(/* webpackChunkName: "Press" */ '../views/Press.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Faq.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "User/Main" */ '../views/User/Main.vue'),
    redirect: { name: 'account' },
    children: [
      {
        path: 'step1',
        name: 'step1Login',
        component: () => import(/* webpackChunkName: "User/Auth/ForgotPassword" */ '../views/FirstLogin/SelectFavorites.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'step2',
        name: 'step2Login',
        component: () => import(/* webpackChunkName: "User/Auth/ForgotPassword" */ '../views/FirstLogin/AddChromeExtesionStep.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'faveItButton',
        name: 'faveItButton',
        component: () => import(/* webpackChunkName: "FaveItButton" */ '../components/common/user/FaveItButton.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'bookmarklet',
        name: 'bookmarklet',
        component: () => import(/* webpackChunkName: "FaveItButton" */ '../components/common/user/Bookmarklet.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/user-settings',
        name: 'userDashboard',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "User/Dashboard" */ '../views/User/Dashboard.vue'),
        children: [
          {
            path: 'account',
            name: 'account',
            component: () => import(/* webpackChunkName: "Account" */ '../components/common/user/Account.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'billing',
            name: 'billing',
            component: () => import('../components/common/user/Billing.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'purchase',
            name: 'purchase',
            component: () => import('../components/common/user/Payment.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'thanks',
            name: 'thanks',
            component: () => import('../components/common/user/ThankScreen.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'export',
            name: 'export',
            component: () => import(/* webpackChunkName: "Export" */ '../components/common/user/Export.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/:provider/callback',
        name: 'userSocialLogin',
        props: true,
        component: () => import(/* webpackChunkName: "User/Auth/SocialLogin" */ '../views/User/Auth/SocialLogin.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'login',
        name: 'userLogin',
        component: () => import(/* webpackChunkName: "User/Auth/Login" */ '../views/User/Auth/Login.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'register',
        name: 'userRegister',
        component: () => import(/* webpackChunkName: "User/Auth/Register" */ '../views/User/Auth/Register.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'forgot-password',
        name: 'userForgotPassword',
        component: () => import(/* webpackChunkName: "User/Auth/ForgotPassword" */ '../views/User/Auth/ForgotPassword.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'reset-password/:token/:username',
        props: true,
        name: 'userResetPassword',
        component: () => import(/* webpackChunkName: "User/Auth/ResetPassword" */ '../views/User/Auth/ResetPassword.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'confirm-email/:email/',
        name: 'ConfirmAccount',
        component: () => import(/* webpackChunkName: "User/Auth/ConfirmAccountView" */ '../views/User/Auth/ConfirmAccountView.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'confirm-account/:token/',
        props: true,
        name: 'userConfirmAccount',
        component: () => import(/* webpackChunkName: "User/Auth/ResetPassword" */ '../views/User/Auth/ConfirmAccount.vue'),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "Admin/Main" */ '../views/Admin/Main.vue'),
    redirect: { name: 'adminDashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'adminDashboard',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Dashboard" */ '../views/Admin/Dashboard.vue'),
      },
      {
        path: 'users',
        name: 'adminUsersListing',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/Users/Users.vue'),
      },
      {
        path: 'users/:id?',
        name: 'adminUserSingle',
        props: true,
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPage" */ '../views/Admin/Users/User.vue'),
      },
      {
        path: 'sites',
        name: 'adminSitesListing',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/Sites/Sites.vue'),
      },
      {
        path: 'unlistedSites',
        name: 'adminUnlistedSitesListing',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/UnlistedSites/UnlistedSites.vue'),
      },
      {
        path: 'uploadedWebsites',
        name: 'adminUploadedWebsitesListing',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/UploadedWebsites/Websites.vue'),
      },
      {
        path: 'mostPopularSites',
        name: 'mostPopularSites',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/Sites/MostPopularSites.vue'),
      },
      {
        path: 'mostPopularSite/:id',
        name: 'adminMostPopularSiteSingle',
        props: true,
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPage" */ '../views/Admin/Sites/MostPopularSite.vue'),
      },
      {
        path: 'sites/:id?',
        name: 'adminSiteSingle',
        props: true,
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPage" */ '../views/Admin/Sites/Site.vue'),
      },
      {
        path: 'unlistedSites/:id?',
        name: 'adminUnlistedSiteSingle',
        props: true,
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPage" */ '../views/Admin/UnlistedSites/UnlistedSite.vue'),
      },
      {
        path: 'uploadedWebsites/:id?',
        name: 'adminUploadedWebsiteSingle',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/Users/Users" */ '../views/Admin/UploadedWebsites/WebsitePage.vue'),
      },
      {
        path: 'favPages',
        name: 'adminFavPagesListing',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/FavPages/FavPages.vue'),
      },
      {
        path: 'favPages/:id',
        name: 'adminFavPageSingle',
        props: true,
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPage" */ '../views/Admin/FavPages/FavPage.vue'),
      },
      {
        path: 'settings',
        name: 'adminSettings',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/Settings/Settings.vue'),
      },
      {
        path: 'settings/:id',
        name: 'adminSettingPageSingle',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/Settings/Setting.vue'),
      },
      {
        path: 'editor-tokens',
        name: 'adminEditorTokens',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/EditorTokens/EditorTokens.vue'),
      },
      {
        path: 'editor-tokens/:id',
        name: 'adminEditorTokenPageSingle',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/EditorTokens/EditorToken.vue'),
      },
      {
        path: 'email',
        name: 'email',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/Email.vue'),
      },
      {
        path: 'banner-settings',
        name: 'banner-settings',
        meta: {
          authorize: [Role.admin],
          isAdmin: true,
          requiresAuth: true,
        },
        component: () => import(/* webpackChunkName: "Admin/FavPages/FavPages" */ '../views/Admin/BannerSettings.vue'),
      },
    ],
  },
  {
    path: '/:username',
    name: 'myFaves',
    props: true,
    component: () => import(/* webpackChunkName: "FaveItButton" */ '../components/common/user/MyFaves.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    meta: { requiresAuth: false },
  },

  // // a param can be made optional by adding "?"
  // { path: '/optional-params/:foo?' },
  // // a param can be followed by a regex pattern in parens
  // // this route will only be matched if :id is all numbers
  // { path: '/params-with-regex/:id(\\d+)' },
  // // asterisk can match anything
  // { path: '/asterisk/*' },
  // // make part of the path optional by wrapping with parens and add "?"
  // { path: '/optional-group/(foo/)?bar' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
// console.log('====================');
// console.log('router.BeforeEach');
// console.log('FROM :: ', from);
// console.log('TO :: ', to);
// console.log('NEXT :: ', next);
// console.log('====================');

// TODO disable this to be able to navigate through pages
// TODO enable this to check for permissions
// const { authorize } = to.meta;
// const authenticated = store.getters.isAuthenticated;
// const currentUser = store.getters.user;
//
// if (authorize) {
//   if (!authenticated && (authorize.includes(Role.admin))) {
//     return next({ path: '/admin/login', query: { nextUrl: to.fullPath } });
//   }
//   if (!authenticated && authorize.includes(Role.user)) {
//     return next({ path: '/user/login', query: { nextUrl: to.fullPath } });
//   }
//   if (currentUser && authenticated && authorize.length && !authorize.includes(currentUser.role)) {
//     // role not authorised so redirect to home page - should logoff nad login with the good user before trying again
//     return next({ path: '/' });
//   }
// }

// DYNAMIC ROUTES
// https://github.com/vuejs/vue-router/blob/dev/examples/route-props/app.js

// Sub-domain routing
// console.log(window.location.host);
//
// return next();
// });
//
// router.afterEach((to, from) => {
//   console.log('====================');
//   console.log('router.BeforeEach');
//   console.log('FROM :: ', from);
//   console.log('TO :: ', to);
//   console.log('isAuthenticated', store.getters.isAuthenticated);
//   console.log('====================');
// });
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.getters.isAuthenticated) {
      if (to.meta.isAdmin) {
        if (store.getters.user.role === Role.admin) {
          next();
        } else {
          next({ name: 'myFaves', username: store.getters.username });
        }
      } else {
        next();
      }
    } else {
      next({ name: 'userLogin' });
    }
  } else if (to.name === 'userLogin' || to.name === 'userRegister') {
    if (store.getters.isAuthenticated) {
      next({ name: 'myFaves', username: store.getters.username });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
